import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, CardText, Container, Spinner} from "reactstrap";
import {AdminApi, DefaultApi, MarkdownContentID} from "client";
import Select from 'react-select';
import MarkdownInput from "../components/MarkdownInput";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import GenericSuccessModal from "../components/GenericSuccessModal";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";

interface IProps {
	token?: string;
	dispatch?: any;
}

interface IMarkdownOption {
	value: MarkdownContentID;
	label?: string;
}

const options: IMarkdownOption[] = [
	{value: MarkdownContentID.INSTRUCTION, label: "This is the intro page of the real estate securities market."},
	{
		value: MarkdownContentID.DEMOINFO,
		label: "This is the description that is shown on real estate securities instructions page."
	}
]

const ContentEditing: React.FC<IProps> = ({token, dispatch}) => {

	const [option, setOption] = useState<IMarkdownOption>();
	const [markdownValue, setMarkdownValue] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);

	async function onSelect(option: IMarkdownOption): Promise<void> {
		setLoading(true);
		setOption(option);
		try {
			const {data} = await new DefaultApi().markdownContentIdGet({id: option.value});
			setMarkdownValue(data);
		} catch (err) {
			dispatch(addError(await err.json()));
		}
		setLoading(false);
	}

	async function submitContent(): Promise<void> {
		dispatch(incrementLoading());
		try {
			await new AdminApi(getConfig(token)).adminMarkdownContentPut({
				markdownContent: {
					_id: option.value,
					data: markdownValue,
				}
			});
			await onSelect(option);
		} catch (err) {
			dispatch(addError(await err.json()));
		}
		dispatch(decrementLoading());
	}

	function closeModal() {
		setOpen(false)
	}

	return (
		<>
			<Container className="my-5">
				<h2>Content Editing</h2>

				<p>
					You may select any of the marked customizable content from around the application and edit
					it via markdown. This will then change the content for all users upon refreshing the
					website. For info on the markdown format, please <a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">click here</a>
				</p>
				<label>Select Content</label>
				<Select
					value={option}
					disabled={loading}
					onChange={onSelect}
					options={options}
					searchable={true}
				/>
				{
					loading &&
					<div className="d-flex justify-content-center align-items-center my-4">
						<Spinner size="large"/>
					</div>
				}

				{
					option &&
					<>
						<div className="mt-5">
						<MarkdownInput
							value={markdownValue}
							onChange={setMarkdownValue}
						/>
						</div>
						<div className="mt-5 d-flex justify-content-center align-items-center">
							<Button color="primary" onClick={submitContent}>
								Save
							</Button>
						</div>
					</>
				}
			</Container>
			<GenericSuccessModal
				isOpen={open}
				callback={closeModal}
				title="Content Successfully Changed"
				buttonText="Okay"
				body="Content has been changed, to see the reflected changes in their respective spot, refresh the page that this content is being used for."
			/>
		</>
	)
}

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(ContentEditing);