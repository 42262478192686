import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {CardBody, Container, Card, Button, Row, Col} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {ArticleMarket, RealestateSecuritiesApi} from "client";
import {getConfig} from "../services/clientApis";
import AddNewArticleMarketModal from "../components/AddNewArticleMarketModal";
import ArticleMarketPreview from "../components/ArticleMarketPreview";

interface IRealEstateMarketsPageProps {
	token?: string;
	dispatch?: any;
}

const RealEstateMarketsPage: React.FC<IRealEstateMarketsPageProps> = (props: IRealEstateMarketsPageProps) => {

	const {token} = props;
	const [markets, setMarkets] = useState<Array<ArticleMarket>>([]);
	const [showAddNewArticleMarketModal, setShowAddNewArticleMarketModal] = useState(false);
	const [selectedArticleMarketForEditing, setSelectedArticleMarketForEditing] = useState<ArticleMarket>();

	useEffect(() => {
		getMarkets().then().catch();
	}, []);

	/**
	 * Respond to an article market being selected, in which case show the "add" modal,
	 * which gets recycled for editing
	 *
	 */
	useEffect(() => {
		if (selectedArticleMarketForEditing) {
			setShowAddNewArticleMarketModal(true);
		}
	}, [JSON.stringify(selectedArticleMarketForEditing)]);

	/**
	 * Listens for the form modal closing, in which case clear the "editing"
	 * market if it exists, doing so is required to continue allowing the modal
	 * to be toggled
	 *
	 */
	useEffect(() => {
		if (!showAddNewArticleMarketModal) {
			setSelectedArticleMarketForEditing(null)
		}
	}, [showAddNewArticleMarketModal]);

	/**
	 * Toggle for the add new article market modal
	 *
	 */
	function toggleAddNewArticleMarketModal(): void {
		setShowAddNewArticleMarketModal(!showAddNewArticleMarketModal)
	}

	/**
	 * Handles finishing adding a new article market - hide modal,
	 * clear editing market if one exists, & re-call get api
	 *
	 */
	async function handleFinishAddingArticleMarket(): Promise<void> {
		setShowAddNewArticleMarketModal(false);
		setSelectedArticleMarketForEditing(null);
		await getMarkets();
	}

	/**
	 * Gets the list of Article Markets.
	 * Called on load & onDeleteDone of the individual markets.
	 *
	 */
	async function getMarkets(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesGetArticleMarketListGet({});
			setMarkets(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Iterate over the article markets & create a react node for each one.
	 * Included function to help with selecting one for editing.
	 *
	 * @param _markets
	 */
	function mapMarkets(_markets: Array<ArticleMarket> = []): ReactNode {
		if (_markets.length < 1) {
			return (
				<p className="text-secondary font-italic w-100 text-center">
					No Markets yet.
				</p>
			);
		}

		return _markets.map((_m: ArticleMarket, i: number) => {

			function onSelectForEditingHelper(): void {
				setSelectedArticleMarketForEditing(_m);
			}

			return (
				<Col key={`article-market-preview-${i}`} xs={12} sm={6} md={6} lg={4} className="d-flex mb-3">
					<ArticleMarketPreview
						articleMarket={_m}
						onDoneDelete={getMarkets}
						onSelectForEditing={onSelectForEditingHelper}
					/>
				</Col>
			);
		});
	}

	return (
		<React.Fragment>
			<AddNewArticleMarketModal
				open={showAddNewArticleMarketModal}
				close={toggleAddNewArticleMarketModal}
				onDone={handleFinishAddingArticleMarket}
				editingArticleMarket={selectedArticleMarketForEditing}
			/>

			<Container className="my-4">
				<div className="mb-4">
					<h1>
						Real Estate Article Markets
					</h1>
					<p>
						Markets
					</p>
				</div>

				<Row className="mb-4">
					{mapMarkets(markets)}
				</Row>

				<div>
					<Button color="primary" onClick={toggleAddNewArticleMarketModal}>
						Add New Market
					</Button>
				</div>

			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IRealEstateMarketsPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(RealEstateMarketsPage);
