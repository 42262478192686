import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Admin, AdminApi} from "client";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import GenericSuccessModal from "./GenericSuccessModal";

interface IProps {
	token?: string;
	dispatch?: any;
	admin: Admin;

	onCancel(): void;

	onFinished(): void;
}

const EnableAdminModal: React.FC<IProps> = (props: IProps) => {

	const {token, admin} = props;
	const [showSuccess, setShowSuccess] = useState(false);

	async function enableAdmin(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new AdminApi(getConfig(token)).adminDisableOrReEnableAdminPut({
				adminIDBody: {id: admin._id},
			});

			setShowSuccess(true);
		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function onDone(): void {
		setShowSuccess(false);
		props.onFinished();
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onDone}
			/>

			<Modal
				isOpen={admin && Object.keys(admin).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={props.onCancel}
			>
				{admin && Object.keys(admin).length > 0 && (
					<React.Fragment>

						<ModalHeader toggle={props.onCancel}>Enable admin</ModalHeader>

						<ModalBody className="my-3">
							<p>
								Are you sure you want to enable{" "}<span
								className="font-weight-bold">{admin.userName}</span>?
								<br/><br/>
								They will have access to all of the admin functionaly granted by their
								status{" "}({admin.adminRole}).
							</p>
						</ModalBody>

						<ModalFooter>
							<Button color="link" onClick={props.onCancel}>
								Cancel
							</Button>
							<Button color="primary" onClick={enableAdmin}>
								Confirm
							</Button>
						</ModalFooter>
					</React.Fragment>
				)}
			</Modal>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(EnableAdminModal);
