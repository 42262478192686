import {APIError, Token} from "client";

export interface IMetaStore {
	token?: string;
	loadingCount: number;
	errors?: APIError[];
	sidebarOpen: boolean;
}

export default {
	loadingCount: 0,
	errors: [],
	sidebarOpen: false,
} as IMetaStore;
