import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import GenericSuccessModal from "./GenericSuccessModal";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {MarketApi, MarketIdentifier, Portfolio, PortfolioSecurity, TradingProfile} from "client";
import cloneDeep from "lodash.clonedeep";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import HoldingsBreakdownTable, {IHoldingsBreakdownTableRow} from "./HoldingsBreakdownTable";
import moment from "moment";
import values from "lodash.values";
import {numberWithCommas} from "../utils/formatters";
import {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";
import NumberFormat from "react-number-format";

interface ITakeSecuritiesModalProps {
	token?: string;
	dispatch?: any;
	tp: TradingProfile;
	market: MarketIdentifier;

	onClose(getNewData: boolean): void;
}

interface ITakeSecurityForm {
	quantity?: number;
	marketValue: number;
}

const defaultTakeSecuritiesForm: ITakeSecurityForm = {
	marketValue: -1,
};

const TakeSecuritiesModal: React.FC<ITakeSecuritiesModalProps> = (props: ITakeSecuritiesModalProps) => {

	const {token, tp, market} = props;
	const [dataClone, setDataClone] = useState<TradingProfile>();
	const [portfolio, setPortfolio] = useState<Portfolio>();
	const [securityToTake, setSecurityToTake] = useState<PortfolioSecurity>();
	const [form, setForm] = useState<ITakeSecurityForm>(defaultTakeSecuritiesForm);
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (tp && Object.keys(tp).length > 0) {
			readPortfolio().then().catch();
			setDataClone(cloneDeep(tp))
		}
	}, [JSON.stringify(tp)]);

	function closeHelper(): void {
		setForm(defaultTakeSecuritiesForm);
		props.onClose(false);
	}

	async function readPortfolio(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketAdminGetPortfolioGet({
				id: tp._id,
			});

			setPortfolio(res);

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	function onMarketValueChange(e): void {
		setForm({...form, marketValue: e.target.value});
	}

	function onQuantityChange(e): void {
		let v = e.floatValue;
		if (form.marketValue === -1) {
			return;
		}

		let adjustedOwned: number = securityToTake[form.marketValue == 1 ? "trueOwned" : "falseOwned"];

		if (getQty(v) > adjustedOwned) {
			// if (adjustedOwned !== 0) {
				v = adjustedOwned / parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER);
			// }
		} else if (adjustedOwned === 0) {
			return;
		}

		setForm({
			...form,
			quantity: v,
		});
	}

	function onSuccessfulSubmission(): void {
		setShowSuccess(false);
		setSecurityToTake(null);
		setForm(defaultTakeSecuritiesForm);
		props.onClose(true);
	}

	function createHoldings(holdings: { [key: string]: PortfolioSecurity }): ReactNode {

		const holdingsData: Array<IHoldingsBreakdownTableRow> = values(holdings).map((s: PortfolioSecurity): IHoldingsBreakdownTableRow => {
			const total: number = s.trueOwned + s.falseOwned + s.trueInvested + s.falseInvested;

			return {
				raw: s,
				propertyType: s.security.name,
				expirationDate: moment(s.security.expirationDate).format("MMM Do YYYY"),
				priceAtExpiration: s.security.expectedValueAtExpiration as number,
				aboveMarketAvailable: s.trueOwned,
				belowMarketAvailable: s.falseOwned,
				aboveMarketInvested: s.trueInvested,
				belowMarketInvested: s.falseInvested,
				total,
				onTake: (_ps: PortfolioSecurity) => {
					setSecurityToTake(_ps)
				}
			}
		});

		return (
			<div className="border-muted border manage-profiles-view-portfolio-table">
				<HoldingsBreakdownTable data={holdingsData} includeTakeButton={true}/>
			</div>
		);
	}

	async function submitTakeRequest(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new MarketApi(getConfig(token)).marketAdminTakeSecuritiesFromUserPost({
				adminTakeSecuritiesFromUserBody: {
					tradingProfile: tp,
					security: securityToTake.security,
					expectedTrue: form.marketValue == 1,
					quantity: Math.round(form.quantity * parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER)),
				},
			});

			setShowSuccess(true);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * calculate the total amount of the selected security that is already owned
	 * to be displayed in the bottom part of the form.
	 *
	 * Accounts for expectedTrue & counts both owned & invested of that security type
	 *
	 */
	let amountOfSelectedSecurityOwned: number = 0;
	if (portfolio && dataClone && securityToTake && form.marketValue > -1) {
		const ownedSelectedSecurity: PortfolioSecurity = securityToTake;
		amountOfSelectedSecurityOwned = form.marketValue == 1 ? (ownedSelectedSecurity.trueOwned) : (ownedSelectedSecurity.falseOwned);
	}

	const q: boolean = form.quantity as any !== "" && form.quantity != undefined && form.quantity != 0;

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onSuccessfulSubmission}
				body="Securities taken from user successfully."
			/>

			<Modal
				isOpen={securityToTake && Object.keys(securityToTake).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={() => {
				}}
			>
				<ModalHeader toggle={() => {
				}}>Confirm Security To Take</ModalHeader>

				{securityToTake && (
					<ModalBody>
						<div>
							<Label for="marketLabel" className="text-secondary">Market</Label>
							<p>
								{getMarketDisplayName(market)}
							</p>
						</div>
						<div>
							<Label for="userNameLabel" className="text-secondary">User</Label>
							<p>
								{tp.parentUser["email"]}
							</p>
						</div>
						<div>
							<Label for="securityName" className="text-secondary">Security</Label>
							<p>
								{securityToTake.security.name}
							</p>
						</div>
						<div className="mb-3">
							<Label for="marketValue">Market Value</Label>
							<Input type="select" id="marketValue" name="marketValue" placeholder="Market Value"
							       onChange={onMarketValueChange} value={form.marketValue}>
								<option value={-1} disabled selected className="text-muted">Select Above/Below</option>
								<hr/>
								<option value={1} className="text-muted">Above</option>
								<option value={0} className="text-muted">Below</option>
							</Input>
						</div>

						{form.marketValue !== -1 && (
							<React.Fragment>
								<div className="mb-3">
									<Label for="quantity">Quantity <span
										className="text-secondary">{`(maximum ${numberWithCommas(amountOfSelectedSecurityOwned)} - user's quantity of selected security)`}</span></Label>
									<Row className="mb-3">
										<Col xs={12} sm={6} className="mb-2 mb-sm-0">
											<NumberFormat
												allowLeadingZeros={false}
												placeholder="Quantity"
												value={form.quantity}
												customInput={Input}
												thousandSeparator={true}
												decimalScale={0}
												onValueChange={onQuantityChange}
												allowNegative={false}
											/>
										</Col>
										<Col xs={12} sm={6}
										     className="d-flex align-items-center justify-content-center justify-content-sm-start">
											<p className="mb-0">
										<span className="text-muted">
											{`x 1,000 = `}
										</span>
												{form.quantity ? getQty(form.quantity, true) : ""}
											</p>
										</Col>
									</Row>
								</div>

								{q && (
									<div>
										<div className="d-flex justify-content-between">
											<span className="mr-2">
												User's quantity of selected security
											</span>
											<span>
												{numberWithCommas(amountOfSelectedSecurityOwned)}
											</span>
										</div>

										<div className="d-flex justify-content-between text-muted">
											<span className="mr-2">
												This action
											</span>
											<span className="border-bottom border-dark">
												-{getQty(form.quantity, true)}
											</span>
										</div>

										<div className="d-flex justify-content-between mt-2">
											<span className="mr-2">
												User's quantity of selected security after this action
											</span>
											<span className={((amountOfSelectedSecurityOwned - (getQty(form.quantity) as number)) < 0) ? "text-danger" : ""}>
												{numberWithCommas(amountOfSelectedSecurityOwned - (getQty(form.quantity) as number))}
											</span>
										</div>
									</div>
								)}
							</React.Fragment>
						)}
					</ModalBody>
				)}

				<ModalFooter>
					<Button color="primary" onClick={submitTakeRequest} disabled={form.marketValue < 0 || !q}>
						Take Securities
					</Button>
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={tp && Object.keys(tp).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				className="modal-max-1800"
				toggle={closeHelper}
			>
				<ModalHeader toggle={closeHelper}>Take Securities</ModalHeader>

				{dataClone && portfolio && (
					<ModalBody>
						<div className="my-3">
							<Label for="holdings" className="text-secondary">Holdings (Table can be scrolled vertically
								when greater than 5 rows)</Label>
							{createHoldings(portfolio.securities)}
						</div>
					</ModalBody>
				)}

				<ModalFooter>
					<Button color="primary" onClick={closeHelper}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>

		</React.Fragment>
	)
};

/**
 * get quantity of the current offer being filled out (quantity x 1000)
 *
 * @param x
 * @param asString
 */
function getQty(x: number, asString: boolean = false): number | string {
	const q: number = Math.round(x * parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER));
	return asString ? numberWithCommas(q) : q;
}

export default connect((store: IStore, props: ITakeSecuritiesModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TakeSecuritiesModal);
