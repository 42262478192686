import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {AdminApi, MarketApi, MarketIdentifier, Portfolio, TradingProfile} from "client";
import cloneDeep from "lodash.clonedeep";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";
import NumberFormat from "react-number-format";
import {formatCurrency} from "../utils/formatters";
import GenericSuccessModal from "./GenericSuccessModal";

interface ITakeMoneyModalProps {
	token?: string;
	dispatch?: any;
	tp: TradingProfile;
	market: MarketIdentifier;

	onClose(getNewData: boolean): void;
}

const defaultTakeMoneyForm: any = {};

const TakeMoneyModal: React.FC<ITakeMoneyModalProps> = (props: ITakeMoneyModalProps) => {

	const {token, tp, market} = props;
	const [dataClone, setDataClone] = useState<TradingProfile>();
	const [portfolio, setPortfolio] = useState<Portfolio>();
	const [form, setForm] = useState<{ amount: number }>(defaultTakeMoneyForm);
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (tp && Object.keys(tp).length > 0) {
			readPortfolio().then().catch();
			setDataClone(cloneDeep(tp))
		}
	}, [JSON.stringify(tp)]);

	function closeHelper(): void {
		setForm(defaultTakeMoneyForm);
		props.onClose(false);
	}

	async function readPortfolio(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketAdminGetPortfolioGet({
				id: tp._id,
			});

			setPortfolio(res);

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	function onNumberChange(e): void {
		if (!portfolio) {
			return;
		}

		let v = e.floatValue;
		if (v > portfolio.availableMoney) {
			v = portfolio.availableMoney;
		}

		setForm({
			...form,
			amount: v,
		});
	}

	async function submitTakeRequest(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new MarketApi(getConfig(token)).marketAdminTakeMoneyFromUserPost({
				adminTakeMoneyFromUserBody: {
					tradingProfile: tp,
					monetaryAmount: form.amount,
				},
			});

			setShowSuccess(true);
		} catch (err) {
			props.dispatch(addError(await err.json()));
		}

		props.dispatch(decrementLoading());
	}

	function onSuccessfulSubmission(): void {
		setShowSuccess(false);
		setForm(defaultTakeMoneyForm);
		props.onClose(true);
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onSuccessfulSubmission}
				body="Money taken from user successfully."
			/>

			<Modal
				isOpen={tp && Object.keys(tp).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={closeHelper}
			>
				<ModalHeader toggle={closeHelper}>Take Money</ModalHeader>

				{(dataClone && portfolio) && (
					<ModalBody>
						<p>
							{`You are taking money from the user with email: "${dataClone.parentUser["email"]}" in the ${getMarketDisplayName(market)} market.`}
						</p>

						<div className="mb-3">
							<Label for="amount">Amount <span
								className="text-muted font-italic">{`(maximum ${formatCurrency(portfolio.availableMoney)} - user's available funds)`}</span></Label>
							<NumberFormat
								prefix="$"
								allowLeadingZeros={false}
								placeholder="Take how much?"
								value={form.amount}
								customInput={Input}
								thousandSeparator={true}
								decimalScale={2}
								onValueChange={onNumberChange}
								allowNegative={false}
							/>
						</div>

						{((form.amount as any) !== "" && form.amount !== undefined) && (
							<div>
								<div className="d-flex justify-content-between">
									<span className="mr-2">
										User's available balance
									</span>
									<span>
										{formatCurrency(portfolio.availableMoney)}
									</span>
								</div>

								<div className="d-flex justify-content-between text-muted">
									<span className="mr-2">
										This action
									</span>
									<span className="border-bottom border-dark">
										-{formatCurrency(form.amount)}
									</span>
								</div>

								<div className="d-flex justify-content-between mt-2">
									<span className="mr-2">
										User's available balance after this action
									</span>
									<span className={((portfolio.availableMoney - form.amount) < 0) ? "text-danger" : ""}>
										{formatCurrency(portfolio.availableMoney - (form.amount))}
									</span>
								</div>
							</div>
						)}
					</ModalBody>
				)}

				<ModalFooter>
					<Button color="primary" onClick={submitTakeRequest} disabled={!form.amount}>
						Take Money
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: ITakeMoneyModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TakeMoneyModal);
