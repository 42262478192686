import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {
	AdminGiftSecuritiesBody,
	MarketApi,
	MarketGetSecuritiesForMarketGetIsActiveEnum,
	MarketIdentifier,
	Security,
	TradingProfile
} from "client";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import NumberFormat from "react-number-format";
import cloneDeep from "lodash.clonedeep";
import GenericSuccessModal from "./GenericSuccessModal";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import findIndex from "lodash.findindex";
import {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";

interface IGiftSecuritiesModalProps {
	token?: string;
	dispatch?: any;
	tp: TradingProfile;
	market: MarketIdentifier;

	onClose(getNewData: boolean): void;
}

const defaultGiftSecuritiesForm: Partial<AdminGiftSecuritiesBody> = {
	security: null,
	expectedTrue: true,
	quantity: "" as any,
};

const GiftSecuritiesModal: React.FC<IGiftSecuritiesModalProps> = (props: IGiftSecuritiesModalProps) => {

	const {token, tp, market} = props;
	const [dataClone, setDataClone] = useState<TradingProfile>();
	const [form, setForm] = useState<Partial<AdminGiftSecuritiesBody>>(defaultGiftSecuritiesForm);
	const [showSuccess, setShowSuccess] = useState(false);
	const [securities, setSecurities] = useState<Array<Security>>([]);

	useEffect(() => {
		if (tp && Object.keys(tp).length > 0) {
			readSecurities().then().catch();
			setDataClone(cloneDeep(tp))
		}
	}, [JSON.stringify(tp)]);

	/**
	 * read list of securities in the currently selected market
	 *
	 */
	async function readSecurities(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetSecuritiesForMarketGet({
				isActive: MarketGetSecuritiesForMarketGetIsActiveEnum.Both,
				marketIdentifier: market,
			});

			setSecurities(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	function closeHelper(): void {
		setForm(defaultGiftSecuritiesForm);
		props.onClose(false);
	}

	function onSuccessfulSubmission(): void {
		setForm(defaultGiftSecuritiesForm);
		setShowSuccess(false);
		props.onClose(true);
	}

	/**
	 * quantity on change
	 *
	 * @param e
	 */
	function onNumberChange(e): void {
		let v = e.floatValue;
		if (v > 1000000000) {
			v = 1000000000;
		}

		setForm({
			...form,
			quantity: v,
		})
	}

	/**
	 * logic for handling above/below, represented as 1/0, saved as true/false
	 *
	 * @param e
	 */
	function createOnExpectedTrueChange(e): void {
		setForm({
			...form,
			expectedTrue: e.target.value == 1,
		});
	}

	/**
	 * find the index of the selected security from the
	 * drop down and then select the correct security
	 *
	 * @param e
	 */
	function onSecurityChange(e): void {
		const foundSecurity: number = findIndex(securities, {_id: e.target.value});
		setForm({
			...form,
			security: securities[foundSecurity],
		});
	}

	/**
	 * Call api to submit the security gift
	 *
	 */
	async function submitGiftSecurities(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketAdminGiftSecuritiesPost({
				adminGiftSecuritiesBody: {
					tradingProfile: tp,
					security: form.security,
					expectedTrue: form.expectedTrue,
					quantity: form.quantity,
				}
			});

			setShowSuccess(true);

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onSuccessfulSubmission}
			/>

			<Modal
				isOpen={tp && Object.keys(tp).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={closeHelper}
			>
				<ModalHeader toggle={closeHelper}>Gift Securities</ModalHeader>

				{dataClone && (
					<ModalBody>
						<p>
							{`You are gifting securities to the user with email: "${dataClone.parentUser["email"]}" in the ${getMarketDisplayName(market)} market.`}
						</p>

						<div className="mb-3">
							<Label for="security">Select Security</Label>
							<Input type="select" id="security" name="security" placeholder="Select Security"
							       onChange={onSecurityChange} value={form.security ? form.security._id : -1}>
								<option value={-1} disabled>Select Security</option>
								<hr/>
								{securities.map((s: Security, i: number) => {
									return (
										<option value={s._id} key={`security-${i}`}>{s.name}</option>
									);
								})}
							</Input>
						</div>

						<div className="mb-3">
							<Label for="marketValue">Market Value</Label>
							<Input type="select" id="marketValue" name="marketValue" placeholder="Market Value"
							       onChange={createOnExpectedTrueChange} value={form.expectedTrue ? 1 : 0}>
								<option value="" disabled selected className="text-muted">Select Above/Below</option>
								<hr/>
								<option value={1} className="text-muted">Above</option>
								<option value={0} className="text-muted">Below</option>
							</Input>
						</div>

						<div>
							<Label for="quantity">Quantity</Label>
							<NumberFormat
								placeholder="Quantity"
								allowLeadingZeros={false}
								value={form.quantity}
								customInput={Input}
								thousandSeparator={true}
								decimalScale={0}
								onValueChange={onNumberChange}
								allowNegative={false}
							/>
						</div>
					</ModalBody>
				)}

				<ModalFooter>
					<Button color="primary" onClick={submitGiftSecurities}>
						Gift Securities
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IGiftSecuritiesModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(GiftSecuritiesModal)
