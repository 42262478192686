import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import GenericSuccessModal from "./GenericSuccessModal";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import MarketIdentifierDropDownValues, {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";
import NumberFormat from "react-number-format";
import {AdminApi, AdminUpdateSecurityBody, MarketApi, MarketIdentifier, Security} from "client";
import moment from "moment";
import {formatCurrency} from "../utils/formatters";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";

interface IEditSecurityModalProps {
	token?: string;
	dispatch?: any;
	security: Security;
	market: MarketIdentifier;

	onFinished(): void;

	onCancel(): void;
}

const defaultEditSecurityForm: Partial<AdminUpdateSecurityBody> | any = {
	security: null,
	name: "",
	// @ts-ignore
	expirationDate: "",
};

const EditSecurityModal: React.FC<IEditSecurityModalProps> = (props: IEditSecurityModalProps) => {

	const {token, security, market} = props;
	const [form, setForm] = useState<Partial<AdminUpdateSecurityBody> | any>(defaultEditSecurityForm);
	const [dataClone, setDataClone] = useState<Security>();
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (security && Object.keys(security).length > 0) {
			setDataClone(security);
			setForm({
				name: security.name,
				// @ts-ignore
				expirationDate: moment(security.expirationDate).format("YYYY-MM-DD") as string, // parse to usable format for date input field
				expectedValueAtExpiration: security.expectedValueAtExpiration,
				realValueAtExpiration: security.realValueAtExpiration,
			});
		}
	}, [JSON.stringify(security)]);

	function closeHelper(): void {
		setForm(defaultEditSecurityForm);
		props.onCancel();
	}

	function onSuccessfulSubmission(): void {
		setForm(defaultEditSecurityForm);
		setShowSuccess(false);
		props.onCancel();
	}

	function createOnChange(key: keyof any): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({...form, [key]: e.target.value});
		}
	}

	function onNumberChange(key: keyof any): (e) => void {
		return (e) => {
			let v = e.floatValue;
			if (v > 1000000000) {
				v = 1000000000;
			}

			setForm({
				...form,
				[key]: v,
			})
		}
	}

	async function updateSecurity(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketAdminUpdateSecurityPut({
				adminUpdateSecurityBody: {
					security,
					name: form.name,
					description: form.name,
				},
			});

			setShowSuccess(true);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}


	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onSuccessfulSubmission}
				body="Security updated successfully"
			/>

			<Modal
				isOpen={security && Object.keys(security).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={closeHelper}
			>
				{dataClone && (
					<React.Fragment>
						<ModalHeader toggle={closeHelper}>Editing Security</ModalHeader>

						<ModalBody>
							<div>
								<h5>
									Current Values
								</h5>

								<div className="mb-3">
									<Label for="market" className="text-secondary">Market</Label>
									<p>
										{getMarketDisplayName(market)}
									</p>
								</div>

								<div className="mb-3">
									<Label for="creationDate" className="text-secondary">Creation Date (Can't be
										edited)</Label>
									<p>
										{moment(dataClone.creationDate).format("MMM Do YYYY")}
									</p>
								</div>

								<div className="mb-3">
									<Label for="security" className="text-secondary">Current Security Name</Label>
									<p>
										{dataClone.name}
									</p>
								</div>

								<div className="mb-3">
									<Label for="currentExpirationDate" className="text-secondary">Current Expiration
										Date</Label>
									<p>
										{`${moment(dataClone.expirationDate).format("YYYY-MM-DD")} (${moment(dataClone.expirationDate).format("MMM Do YYYY")})`}
									</p>
								</div>

								<div className="mb-3">
									<Label for="currentExpectedValue" className="text-secondary">Current Expected Value
										at Expiration</Label>
									<p>
										{formatCurrency(dataClone.expectedValueAtExpiration)}
									</p>
								</div>

								<div className="mb-3">
									<Label for="currentRealValue" className="text-secondary">Current Real Value at
										Expiration</Label>
									<p>
										{formatCurrency(dataClone.realValueAtExpiration)}
									</p>
								</div>
							</div>

							<hr/>

							<div>
								<h5>
									Edit Values
								</h5>

								<div className="mb-3">
									<Label for="name">Change Name</Label>
									<Input value={form.name} placeholder="Security Name"
									       onChange={createOnChange("name")}/>
								</div>

								{/*<div className="mb-3">*/}
								{/*	<Label>Expiration Date</Label>*/}
								{/*	<Input type="date" value={form.expirationDate} placeholder="Expiration Date"*/}
								{/*	       onChange={createOnChange("expirationDate")}/>*/}
								{/*</div>*/}

								{/*<div className="mb-3">*/}
								{/*	<Label>Expected Value at Expiration</Label>*/}
								{/*	<NumberFormat*/}
								{/*		placeholder="Expected Value"*/}
								{/*		prefix="$"*/}
								{/*		allowLeadingZeros={false}*/}
								{/*		value={form.expectedValueAtExpiration as any}*/}
								{/*		customInput={Input}*/}
								{/*		thousandSeparator={true}*/}
								{/*		decimalScale={2}*/}
								{/*		onValueChange={onNumberChange("expectedValueAtExpiration")}*/}
								{/*		allowNegative={false}*/}
								{/*	/>*/}
								{/*</div>*/}

								{/*<div className="mb-3">*/}
								{/*	<Label>Real Value at Expiration</Label>*/}
								{/*	<NumberFormat*/}
								{/*		placeholder="Real Value"*/}
								{/*		prefix="$"*/}
								{/*		allowLeadingZeros={false}*/}
								{/*		value={form.realValueAtExpiration as any}*/}
								{/*		customInput={Input}*/}
								{/*		thousandSeparator={true}*/}
								{/*		decimalScale={2}*/}
								{/*		onValueChange={onNumberChange("realValueAtExpiration")}*/}
								{/*		allowNegative={false}*/}
								{/*	/>*/}
								{/*</div>*/}
							</div>
						</ModalBody>

						<ModalFooter>
							<Button color="link" onClick={closeHelper}>
								Cancel
							</Button>

							<Button color="primary" onClick={updateSecurity}>
								Update Security
							</Button>
						</ModalFooter>
					</React.Fragment>
				)}

			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IEditSecurityModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(EditSecurityModal);
