import React from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-editor.css";
import "react-mde/lib/styles/css/react-mde-suggestions.css";
import "react-mde/lib/styles/css/react-mde-toolbar.css";
import "react-mde/lib/styles/css/react-mde.css";

interface IProps {
	value?: string;
	onChange?: (value: string) => void
}

const converter = new Showdown.Converter({
	tables: true,
	simplifiedAutoLink: true,
	strikethrough: true,
	tasklists: true
});

const MarkdownInput: React.FC<IProps> = ({value, onChange}) => {

	const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");

	return (
		<ReactMde
			value={value}
			onChange={onChange}
			selectedTab={selectedTab}
			onTabChange={setSelectedTab}
			generateMarkdownPreview={markdown =>
				Promise.resolve(converter.makeHtml(markdown))
			}
		/>
	);
}

MarkdownInput.defaultProps = {
	value: "",
	onChange: () => {}
}

export default MarkdownInput;