import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {Order, PortfolioSecurity} from "client";
import {decimalAsPercent, formatCurrency, numberWithCommas} from "../utils/formatters";
import {
	IColumn,
	whiteSpaceCellFormatter
} from "../utils/tableCellHTMLFormatters";
import {Button} from "reactstrap";
import {ITradingProfilesTableRow} from "./TradingProfilesTable";

export interface IHoldingsBreakdownTableRow {
	raw: PortfolioSecurity,
	propertyType: string;
	expirationDate: string;
	priceAtExpiration: number;
	aboveMarketAvailable: number;
	belowMarketAvailable: number;
	aboveMarketInvested: number;
	belowMarketInvested: number;
	total: number;
	// totalShare: number;
	onTake?(_ps: PortfolioSecurity): void;
}

interface IHoldingsBreakdownTableProps {
	data: Array<IHoldingsBreakdownTableRow>;
	includeTakeButton?: boolean;
}

const HoldingsBreakdownTable: React.FC<IHoldingsBreakdownTableProps> = (props: IHoldingsBreakdownTableProps) => {

	const {data} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			hover={true}
			keyField="id"
			data={data}
			columns={props.includeTakeButton ? columnsWithTakeAction : columns}
			bordered={false}
			noDataIndication="User currently has no holdings."
			defaultSorted={[{dataField: "propertyType", order: "asc"}]}
		/>
	);
};

const columns: Array<IColumn> = [
	{dataField: "propertyType", text: "Security", formatter: whiteSpaceCellFormatter, sort: true},
	{dataField: "expirationDate", text: "Expiration Date", sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "priceAtExpiration", text: "Price At Expiration", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "aboveMarketAvailable", text: "Above Market Available", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "belowMarketAvailable", text: "Below Market Available", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "aboveMarketInvested", text: "Above Market Invested", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "belowMarketInvested", text: "Below Market Invested", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "total", text: "Total", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	// {dataField: "totalShare", text: "Total Share", formatter: decimalAsPercent, sort: true, headerAlign: "right", classes: "text-right"},
];

const columnsWithTakeAction: Array<IColumn> = columns.concat([{
	dataField: "actions",
	text: "Take",
	formatter: makeTakeButton,
}]);

function makeTakeButton(cell: string, row: IHoldingsBreakdownTableRow): JSX.Element {

	function onTakeHelper(): void {
		row.onTake(row.raw);
	}

	return (
		<Button onClick={onTakeHelper} color="danger">
			Take
		</Button>
	)
}

export default HoldingsBreakdownTable;
