import React, {useState} from "react";
import {Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Article, RealestateSecuritiesApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {FiX} from "react-icons/all";

interface IArticlePreviewProps {
	token?: string;
	dispatch?: any;
	article: Article;

	onDoneDelete(): Promise<void>;

	onSelectForEditing(): void;
}

const ArticlePreview: React.FC<IArticlePreviewProps> = (props: IArticlePreviewProps) => {

	const {token, article} = props;
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	function toggleShowDeleteModal(): void {
		setShowDeleteModal(!showDeleteModal);
	}

	/**
	 * Call backend and delete this article, on success call props.onDoneDelete();
	 *
	 */
	async function deleteArticleMarket(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesArticleDelete({article});

			toggleShowDeleteModal();
			await props.onDoneDelete();
			props.dispatch(decrementLoading())
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(await e.json()));
		}

	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showDeleteModal}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={toggleShowDeleteModal}
			>
				<ModalHeader toggle={toggleShowDeleteModal}>Delete this article?</ModalHeader>

				<ModalBody>
					<p>{`Are you sure you want to delete the article titled "${article.title}"?`}</p>
				</ModalBody>

				<ModalFooter className="d-flex justify-content-between">
					<Button color="link" onClick={toggleShowDeleteModal}>
						Cancel
					</Button>

					<Button color="danger" onClick={deleteArticleMarket}>
						Yes, Delete
					</Button>
				</ModalFooter>
			</Modal>

			<Card className="w-100">
				<CardBody>
					<div className="d-flex justify-content-end mb-2">
						<a className="text-danger" href="#" onClick={toggleShowDeleteModal}>
							<FiX size="1.75rem" style={{maxHeight: 50}}/>
						</a>
					</div>

					<div className="d-flex justify-content-center mb-3">
						<img
							src={(article && article.thumbnail) ? article.thumbnail.url : ""}
							style={{
								maxWidth: "100%",
								height: 200,
								borderRadius: 5,
								objectFit: "contain",
							}}
						/>
					</div>

					<h4>
						{article.title}
					</h4>

					<a href={article.articleURL} target="_blank">
						{article.articleURL}
					</a>

					<p>
						{`Article Source: ${article.source}`}
					</p>

					<p className="text-secondary font-italic">
						{`This article ${article.hideFromNewsFeed ? "does NOT" : "DOES"} appear on the News Feed.`}
					</p>

					<div className="mb-3">
						{article.articleMarkets.length > 0 ? (
							<React.Fragment>
								<p className="mb-0">
									This article appears in the following Article Market(s):
								</p>
								{article.articleMarkets.map((a, i: number) => {
									return (
										<li key={`article-market-${i}`}>
											{a.title}
										</li>
									)
								})}
							</React.Fragment>
						) : (
							<p className="mb-0">
								This article does not appear in any Article Markets.
							</p>
						)}
					</div>


					<p>
						{article.description}
					</p>

					<div className="d-flex justify-content-center">
						<Button color="info" onClick={props.onSelectForEditing}>
							Edit Article
						</Button>
					</div>

				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IArticlePreviewProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ArticlePreview);
