import React, {useEffect} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Container} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {MarketApi, MarketIdentifier} from "client";
import {getConfig} from "../services/clientApis";

interface IUpdateExpiredSecurityRealValuesPageProps {
	token?: string;
	dispatch?: any;
}

const UpdateExpiredSecurityRealValuesPage: React.FC<IUpdateExpiredSecurityRealValuesPageProps> = (props: IUpdateExpiredSecurityRealValuesPageProps) => {

	const {token} = props;

	useEffect(() => {
		readExpiredSecurities().then().catch();
	}, []);

	async function readExpiredSecurities(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketAdminGetExpiredSecuritiesMissingRealValueGet({marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver});

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<Container className="my-5">
			Update Real Values Page
		</Container>
	);
};

export default connect((store: IStore, props: IUpdateExpiredSecurityRealValuesPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(UpdateExpiredSecurityRealValuesPage);
