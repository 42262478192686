import React, {ReactNode} from "react";
import BootstrapTable, {Column} from "react-bootstrap-table-next";
import {Admin} from "client";
import {Button} from "reactstrap";

export interface IAdminsTableRow {
	raw: Admin;
	onPressEditPassword(admin: Admin): void;
	onDisable(admin: Admin): void;
	onEnable(admin: Admin): void;
}

interface IAdminsTableProps {
	data: Array<IAdminsTableRow>;
}

const AdminsTable: React.FC<IAdminsTableProps> = (props: IAdminsTableProps) => {

	const {data} = props;

	function createNoDataMessage(): ReactNode {
		return (
			<div className="my-2">
				<p className="text-center">
					No Admins
				</p>
			</div>
		)
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			columns={[
				{
					dataField: "raw[userName]",
					text: "User Name",
					sort: true,
				},
				{
					dataField: "raw[adminRole]",
					text: "Admin Role",
					sort: true,
				},
				{
					dataField: "raw[firstName]",
					text: "First Name",
					sort: true,
				},
				{
					dataField: "raw[lastName]",
					text: "Last Name",
					sort: true,
				},
				{
					dataField: "raw[email]",
					text: "Email",
					sort: true,
				},
				{
					dataField: "manageButtons",
					text: "Manage",
					formatter: makeManageButtons,
				}
			]}
			keyField={"key"}
			data={
				data.filter((d) => {
					for (let k in d) {
						if (!d.hasOwnProperty(k)) {continue}
						if ((typeof d[k] === "string" || typeof d[k] === "undefined") && (!d[k] || d[k].length < 1)) {
							d[k] = "-"
						}
					}
					return d;
				}).map((d: IAdminsTableRow, i: number) => {
					return {...d, key: i}
				})
			}
			noDataIndication={createNoDataMessage}
			defaultSorted={[{dataField: "username", order: "asc"}]}
		/>
	);
};

function makeManageButtons(cell: string, row: IAdminsTableRow): ReactNode {

	function onEdit(): void {
		row.onPressEditPassword(row.raw);
	}

	function onDisableHelper(): void {
		row.onDisable(row.raw);
	}

	function onEnableHelper(): void {
		row.onEnable(row.raw);
	}

	return (
		<div className="d-flex">
			<Button color="primary" onClick={onEdit} className="mr-3" >
				Edit Password
			</Button>

			{row.raw.active ? (
				<Button color="danger" onClick={onDisableHelper}>
					Disable Admin
				</Button>
			) : (
				<Button color="success" onClick={onEnableHelper}>
					Enable Admin
				</Button>
			)}
		</div>
	);
}

export default AdminsTable;
