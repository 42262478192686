import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Container, Input, Label} from "reactstrap";
import {IStore} from "../services/redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {AdminApi, MarketApi, MarketIdentifier, TradingProfile, User} from "client";
import GiftMoneyModal from "../components/GiftMoneyModal";
import GiftSecuritiesModal from "../components/GiftSecuritiesModal";
import MarketIdentifierDropDownValues from "../components/MarketIdentifierDropDownOptions";
import {getConfig} from "../services/clientApis";
import cloneDeep from "lodash.clonedeep";
import TradingProfilesTable, {ITradingProfilesTableRow} from "../components/TradingProfilesTable";
import ViewPortfolioModal from "../components/ViewPortfolioModal";
import TakeMoneyModal from "../components/TakeMoneyModal";
import TakeSecuritiesModal from "../components/TakeSecuritiesModal";

interface IManageTradingProfilesPageProps {
	token?: string;
	dispatch?: any;
}

const ManageTradingProfilesPage: React.FC<IManageTradingProfilesPageProps> = (props: IManageTradingProfilesPageProps) => {

	const {token} = props;
	const [selectedMarket, setSelectedMarket] = useState<MarketIdentifier>(MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver);
	const [tradingProfiles, setTradingProfiles] = useState<Array<ITradingProfilesTableRow>>([]);
	const [userToGiftMoney, setUserToGiftMoney] = useState<TradingProfile>();
	const [userToGiftSecurities, setUserToGiftSecurities] = useState<TradingProfile>();
	const [userToViewPortfolio, setUserToViewPortfolio] = useState<TradingProfile>();
	const [userToTakeMoneyFrom, setUserToTakeMoneyFrom] = useState<TradingProfile>();
	const [userToTakeSecuritiesFrom, setUserToTakeSecuritiesFrom] = useState<TradingProfile>();

	useEffect(() => {
		readTradingProfiles().then().catch();
	}, [selectedMarket]);

	function clearSelectedUser(getNewData: boolean = false): void {
		setUserToGiftMoney(null);
		setUserToGiftSecurities(null);
		setUserToViewPortfolio(null);
		setUserToTakeMoneyFrom(null);
		setUserToTakeSecuritiesFrom(null);

		if (getNewData) {
			readTradingProfiles().then().catch();
		}
	}

	/**
	 * onChange for the market selector drop down
	 *
	 * @param e
	 */
	function onSelectedMarketChange(e): void {
		setSelectedMarket(e.target.value);
	}

	/**
	 * Call api and get list of trading profiles based on selected market,
	 * called whenever the drop down is changed
	 *
	 */
	async function readTradingProfiles(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetTradingProfilesForMarketGet({marketIdentifier: selectedMarket});

			const data: Array<ITradingProfilesTableRow> = cloneDeep(res).map((tp: TradingProfile) => {
				return {
					raw: tp,
					...tp,
					onGiveMoney: promptGiftMoneyModal,
					onGiveSecurities: promptGiftSecuritiesModal,
					onViewPortfolio: promptViewPortfolioModal,
					onTakeMoney: promptTakeMoneyModal,
					onTakeSecurities: promptTakeSecurityModal,
				};
			});

			setTradingProfiles(data);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	function promptGiftMoneyModal(_tp: TradingProfile): void  {
		setUserToGiftMoney(_tp);
	}

	function promptGiftSecuritiesModal(_tp: TradingProfile): void  {
		setUserToGiftSecurities(_tp);
	}

	function promptViewPortfolioModal(_tp: TradingProfile): void {
		setUserToViewPortfolio(_tp);
	}

	function promptTakeMoneyModal(_tp: TradingProfile): void {
		setUserToTakeMoneyFrom(_tp);
	}

	function promptTakeSecurityModal(_tp: TradingProfile): void {
		setUserToTakeSecuritiesFrom(_tp);
	}

	return (
		<React.Fragment>
			<GiftMoneyModal tp={userToGiftMoney} market={selectedMarket} onClose={clearSelectedUser}/>
			<GiftSecuritiesModal tp={userToGiftSecurities} market={selectedMarket} onClose={clearSelectedUser}/>
			<ViewPortfolioModal tp={userToViewPortfolio} market={selectedMarket} onClose={clearSelectedUser}/>
			<TakeMoneyModal tp={userToTakeMoneyFrom} market={selectedMarket} onClose={clearSelectedUser}/>
			<TakeSecuritiesModal tp={userToTakeSecuritiesFrom} market={selectedMarket} onClose={clearSelectedUser}/>

			<Container className="my-5">
				<div className="mb-4">
					<h2>Manage Trading Profiles</h2>
					<p>
						On this page you can choose a specific market from the drop-down list below to see a summary of
						the user trading profiles that exist within that market. Once a market has been selected, you
						can
						take specific actions with the buttons in each row to do things such as gift money, gift
						securities,
						etc. to that user.
					</p>
					<div className="d-flex justify-content-center">
						<div className="w-100" style={{maxWidth: 500}}>

							<Label for="market">Select Market</Label>
							<Input type="select" id="market" name="market" placeholder="Select Market"
							       onChange={onSelectedMarketChange} value={selectedMarket}>
								<option value={-1} disabled>Select Market</option>
								<hr/>
								<MarketIdentifierDropDownValues/>
							</Input>
						</div>
					</div>
				</div>

				<TradingProfilesTable data={tradingProfiles}/>
			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IManageTradingProfilesPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ManageTradingProfilesPage);
