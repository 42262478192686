import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Button, Container} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {Token, Admin, AdminApi} from "client";
import {getConfig} from "../services/clientApis";
import AdminsTable, {IAdminsTableRow} from "../components/AdminsTable";
import ChangePasswordModal from "../components/ChangePasswordModal";
import AddNewAdminModal from "../components/AddNewAdminModal";
import DisableAdminModal from "../components/DisableAdminModal";
import EnableAdminModal from "../components/EnableAdminModal";

interface IManageAdminsProps {
	token?: string;
	dispatch?: any;
}

const ManageAdmins: React.FC<IManageAdminsProps> = (props: IManageAdminsProps) => {

	const [admins, setAdmins] = useState<IAdminsTableRow[]>([]);
	const [modifyingAdmin, setModifyingAdmin] = useState<{ a: Admin, t: "edit" | "delete" }>();
	const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showAddNewAdminModal, setShowAddNewAdminModal] = useState(false);
	const [adminToDisable, setAdminToDisable] = useState<Admin>();
	const [adminToEnable, setAdminToEnable] = useState<Admin>();

	useEffect(() => {
		readAdmins().then().catch();
	}, []);

	useEffect(() => {
		if (modifyingAdmin) {
			if (modifyingAdmin.t === "edit") {
				setShowEditPasswordModal(true);
			} else if (modifyingAdmin.t === "delete") {
				setShowDeleteModal(true);
			}
		}
	}, [JSON.stringify(modifyingAdmin)]);

	useEffect(() => {
		if (!showEditPasswordModal) {
			// @ts-ignore
			function h(): void {setModifyingAdmin(null)}

			setTimeout(() => {h()}, 250);
		}
	}, [showEditPasswordModal]);

	useEffect(() => {
		if (!showDeleteModal) {
			// @ts-ignore
			function h(): void {setModifyingAdmin(null)}

			setTimeout(() => {h()}, 250);
		}
	}, [showDeleteModal]);

	function toggleShowNewAdminModal(): void {
		setShowAddNewAdminModal(!showAddNewAdminModal);
	}

	function closeChangePasswordModal(): void {
		setShowEditPasswordModal(false);
	}

	function closeDeleteModal(): void {
		setShowDeleteModal(false);
	}

	function cancelActiveToggling(): void {
		setAdminToDisable(null);
		setAdminToEnable(null)
	}

	function doneActiveToggling(): void {
		cancelActiveToggling();
		readAdmins().then().catch();
	}

	/**
	 * Call database and get the current list of admins, save to "admins" useState on success
	 *
	 */
	async function readAdmins(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new AdminApi(getConfig(props.token)).adminAdminListGet();

			const data: Array<IAdminsTableRow> = res.map((admin: Admin): IAdminsTableRow => {
				return {
					raw: admin,
					onPressEditPassword,
					onDisable,
					onEnable,
				}
			});

			setAdmins(data);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	async function handleFinishAddingAdmin(): Promise<void> {
		setShowAddNewAdminModal(false);
		await readAdmins();
	}

	async function handleFinishUpdatingPassword(): Promise<void> {
		setShowEditPasswordModal(false);
		await readAdmins();
	}

	async function handleFinishDeletingAdmin(): Promise<void> {
		setShowDeleteModal(false);
		await readAdmins();
	}

	function onPressEditPassword(admin: Admin): void {
		setModifyingAdmin({a: admin, t: "edit"});
	}

	function onPressDelete(admin: Admin): void {
		setModifyingAdmin({a: admin, t: "delete"});
	}

	function onDisable(admin: Admin): void {
		setAdminToDisable(admin);
	}

	function onEnable(admin: Admin): void {
		setAdminToEnable(admin);
	}

	return (
		<React.Fragment>
			<AddNewAdminModal
				open={showAddNewAdminModal}
				close={toggleShowNewAdminModal}
				onDone={handleFinishAddingAdmin}
			/>

			{modifyingAdmin && modifyingAdmin.t === "edit" && (
				<ChangePasswordModal
					admin={modifyingAdmin.a}
					open={showEditPasswordModal}
					close={closeChangePasswordModal}
					onDone={handleFinishUpdatingPassword}
				/>
			)}

			<DisableAdminModal
				admin={adminToDisable}
				onCancel={cancelActiveToggling}
				onFinished={doneActiveToggling}
			/>

			<EnableAdminModal
				admin={adminToEnable}
				onCancel={cancelActiveToggling}
				onFinished={doneActiveToggling}
			/>

			<Container className="my-4">
				<div className="mb-4">
					<h1>
						Admins
					</h1>

					<Button color="primary" onClick={toggleShowNewAdminModal}>
						Create new Admin
					</Button>
				</div>

				<AdminsTable data={admins}/>
			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IManageAdminsProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ManageAdmins);
