import {IStore} from "../services/redux/defaultStore";
import {APIError} from "client";
import React, {ReactNode, useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {removeErr} from "../services/redux/meta/MetaActions";
import {connect} from "react-redux";
import {FiMail, FiPhoneCall} from "react-icons/fi";

interface IProps {
	errors?: APIError[]
}

const ErrorModalHandler: React.FC<IProps> = ({errors}) => {
	const modals: ReactNode[] = errors.map((error, i): ReactNode => {
		return <ErrorModal key={"error_" + i} error={error} index={i}/>
	});

	return <>{modals}</>;
};

ErrorModalHandler.defaultProps = {
	errors: []
};

// Error Modal helper class
const _ErrorModal: React.FC<{error: APIError, index: number, dispatch: any}> = ({error, index, dispatch}) => {

	const [open, setOpen] = useState(false);
	const [showContact, setShowContact] = useState(false);

	useEffect(() => {
		if (!open) {
			setOpen(true)
		}
	}, [JSON.stringify(error), index]);

	function makeText(text: string, i: number) {
		return <p key={"message_" + i}>{text}</p>
	}

	function dismiss() {
		setOpen(false);
		dispatch(removeErr(index));
	}

	function toggleSupportModal() {
		setShowContact(!showContact)
	}

	return (
		<React.Fragment>
			<Modal isOpen={showContact} centered={true} toggle={toggleSupportModal}>
				<ModalHeader toggle={toggleSupportModal}>Contact</ModalHeader>
				<ModalBody>
					<p>
						Need to get in touch with us? Text, call, or email us any time.
					</p>

					<div className="mb-3">
						<a href="tel:6043195219" className="text-decoration-none text-info">
							<FiPhoneCall size="1.5rem" style={{maxHeight: 50}} className="mr-3"/>
							(604) 319-5219
						</a>
					</div>

					<div>
						<a href="mailto:christopher@frameonesoftware.com?subject=Frame One Software support"
						   target="_blank" className="text-decoration-none text-info">
							<FiMail size="1.5rem" style={{maxHeight: 50}} className="mr-3"/>
							Christopher@frameonesoftware.com
						</a>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={toggleSupportModal}>Dismiss</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={open} centered={true}>
				<ModalHeader>Error!</ModalHeader>
				<ModalBody>
					{error.messages.map(makeText)}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggleSupportModal}>Contact Support</Button>{' '}
					<Button color="primary" onClick={dismiss}>Dismiss</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

const ErrorModal = connect()(_ErrorModal);

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		errors: store.metaStore.errors,
	}
})(ErrorModalHandler);
