import React, {useEffect} from "react";
import {connect} from "react-redux";
import {DefaultApi, Token} from "client";
import {getConfig} from "../services/clientApis";
import {IStore} from "../services/redux/defaultStore";
import {logout} from "../services/redux/meta/MetaActions";

interface IProps {
	token?: string;
	dispatch?: any;
}

const TokenManager: React.FC<IProps> = (props) => {

	useEffect(() => {
		checkToken().then();
		const interval = setInterval(checkToken,60000);
		return () => clearInterval(interval)
	}, [props.token])

	async function checkToken() {
		// don't need to check on a token existing
		if (!props.token) {
			return
		}

		try {
			const {expired} = await new DefaultApi(getConfig(props.token)).isTokenExpiredPost({token: {token: props.token}});

			if (expired === true) {
				props.dispatch(logout());
			}
		} catch {
		}
	}

	return null;
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token
	}
})(TokenManager);