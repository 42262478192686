import React, {useState} from "react";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {IStore} from "../../services/redux/defaultStore";
import {connect} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "../../services/redux/meta/MetaActions";
import {AdminApi} from "client";
import {getConfig} from "../../services/clientApis";

interface IProps {
	open: boolean;
	close: () => void;
	onUpload?: () => void;
	dispatch?: any;
	token?: string;
}

const AssetUploadModal: React.FC<IProps> = (props) => {

	const {open, close, onUpload, dispatch, token} = props;

	const [name, setName] = useState<string>();
	const [description, setDescription] = useState<string>();
	const [file, setFile] = useState<File>();

	async function uploadAsset() {
		dispatch(incrementLoading());
		try {
			await new AdminApi(getConfig(token)).adminAssetPost({
				name: name,
				image: file,
				description: description,
			});
			close();
			onUpload();
		} catch (err) {
			dispatch(addError(err));
		}
		dispatch(decrementLoading());
	}

	function createOnChange(setFunction): ((e) => void) {
		return (e) => {
			e.target.value.length > 0 ? setFunction(e.target.value) : setFunction(undefined)
		}
	}

	function onFileChange(e): void {
		setFile(e.target.files[0]);
	}

	return (
		<Modal isOpen={open} centered={true} toggle={close}>
			<ModalHeader toggle={close}>
				Upload New Asset
			</ModalHeader>
			<ModalBody>
				<Label>Name:</Label>
				<Input className="mb-1" type="text" value={name} onChange={createOnChange(setName)}/>
				<Label>Description:</Label>
				<Input className="mb-1" type="textarea" value={description} onChange={createOnChange(setDescription)}/>
				<Label>File:</Label>
				<Input type="file" onChange={onFileChange}/>
			</ModalBody>
			<ModalFooter>
				<Button onClick={uploadAsset} color="primary" disabled={!file || !name}>Upload</Button>
			</ModalFooter>
		</Modal>
	)
}

export default connect((store: IStore, props: IProps): IProps => {
	return {
		...props,
		token: store.metaStore.token
	}
})(AssetUploadModal)