import React, {useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {Button, Nav, Navbar, NavItem} from "reactstrap";
import {addError, decrementLoading, incrementLoading, logout} from "../../services/redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../../services/redux/defaultStore";
import AssetManagerModal from "../asset_manager/AssetManagerModal";
import {AdminApi} from "client";
import {getConfig} from "../../services/clientApis";

interface ISidebarContentProps {
	dispatch?: any;
	token?: string;
}

const SidebarContent: React.FC<ISidebarContentProps> = (props: ISidebarContentProps) => {

	const [isOpenAssetManager, setIsOpenAssetManager] = useState(false);

	function toggleAssetManager(e?: any): void {
		if (e) {
			e.preventDefault();
		}
		setIsOpenAssetManager(!isOpenAssetManager);
	}

	async function downloadSourceCode(e?: any): Promise<void> {
		if (e) {
			e.preventDefault();
		}

		props.dispatch(incrementLoading());
		try {
			const blob = await new AdminApi(getConfig(props.token)).adminGetSourceCodeGet();
			const a = document.createElement("a");
			document.body.appendChild(a);
			a.setAttribute("style", "display: none");
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = "source_code.zip";
			a.click();
			window.URL.revokeObjectURL(url);
		} catch (err) {
			props.dispatch(addError(err));
		}
		props.dispatch(decrementLoading());
	}

	function logoutHelper(e?: any): void {
		if (e) {
			e.preventDefault();
		}
		props.dispatch(logout());
	}

	return (
		<div className="w-100">
			<AssetManagerModal open={isOpenAssetManager} close={toggleAssetManager}/>

			<Navbar color="black" dark>
				<Nav navbar className="w-100">
					<NavItem>
						<NavLink exact to="/dashboard" className="text-decoration-none nav-link">Dashboard</NavLink>
					</NavItem>

					<hr className="border-bottom w-100"/>

					<NavItem>
						<NavLink exact to="/admin-manager" className="text-decoration-none nav-link">Manage Admins</NavLink>
					</NavItem>
					<NavItem>
						<NavLink exact to="/user-manager" className="text-decoration-none nav-link">Manage Users</NavLink>
					</NavItem>

					<NavItem>
						<NavLink exact to="/content-editing" className="text-decoration-none nav-link">Content Editing</NavLink>
					</NavItem>

					<hr className="border-bottom w-100"/>

					<div>
						<NavItem>
							<NavLink exact to="/security-management" className="text-decoration-none nav-link">Manage Securities</NavLink>
						</NavItem>
						<NavItem>
							<NavLink exact to="/trading-profiles" className="text-decoration-none nav-link">Manage Trading Profiles</NavLink>
						</NavItem>
						<NavItem>
							<NavLink exact to="/unverified-payments" className="text-decoration-none nav-link">Unverified Payments</NavLink>
						</NavItem>
						<NavItem>
							<NavLink exact to="/real-estate-faq" className="text-decoration-none nav-link">Real Estate FAQ</NavLink>
						</NavItem>
					</div>

					<hr className="border-bottom w-100"/>

					<div>
						<h5 className="text-decoration-none text-secondary">
							Information Center
						</h5>

						<NavItem>
							<NavLink exact to="/useful-links" className="text-decoration-none nav-link">Useful Links</NavLink>
						</NavItem>

						<NavItem>
							<NavLink exact to="/articles" className="text-decoration-none nav-link">Articles</NavLink>
						</NavItem>

						<NavItem>
							<NavLink exact to="/real-estate-article-markets" className="text-decoration-none nav-link">Real Estate Article Markets</NavLink>
						</NavItem>
					</div>

					<hr className="border-bottom w-100"/>

					<a color="link" className="nav-link" onClick={toggleAssetManager} href="#">
						Asset Manager
					</a>

					<a color="link" className="nav-link" onClick={downloadSourceCode} href="#">
						Download Source Code
					</a>

					<a color="link" className="nav-link text-danger" onClick={logoutHelper} href="#">
						Logout
					</a>
				</Nav>
			</Navbar>
		</div>
	);
};

export default connect((store: IStore, props: ISidebarContentProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(SidebarContent);
