import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {MarketApi, MarketIdentifier, Security} from "client";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import GenericSuccessModal from "./GenericSuccessModal";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import moment from "moment";
import {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";

interface IEditSecurityExpirationDateModalProps {
	token?: string;
	dispatch?: any;
	security: Security;
	market: MarketIdentifier;

	onFinished(): void;

	onCancel(): void;
}

const EditSecurityExpirationDateModal: React.FC<IEditSecurityExpirationDateModalProps> = (props: IEditSecurityExpirationDateModalProps) => {

	const {token, security, market} = props;
	const [date, setDate] = useState();
	const [dataClone, setDataClone] = useState<Security>();
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (security && Object.keys(security).length > 0) {
			setDataClone(security);
			setDate(moment(security.expirationDate).format("YYYY-MM-DD")); // parse to usable format for date input field);
		}
	}, [JSON.stringify(security)]);

	function closeHelper(): void {
		setDate("");
		props.onCancel();
	}

	function onSuccessfulSubmission(): void {
		setDate("");
		setShowSuccess(false);
		props.onCancel();
	}

	function onDateChange(e): void {
		setDate(e.target.value);
	}

	async function updateSecurity(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new MarketApi(getConfig(token)).marketAdminUpdateSecurityExpirationDatePut({
				adminUpdateSecurityExpirationDateBody: {
					security,
					expirationDate: moment(date, "YYYY-MM-DD").toDate().getTime(),
				},
			});

			setShowSuccess(true);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onSuccessfulSubmission}
				body="Security updated successfully"
			/>

			<Modal
				isOpen={security && Object.keys(security).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={closeHelper}
			>
				{dataClone && (
					<React.Fragment>
						<ModalHeader toggle={closeHelper}>Editing Security Expiration Date</ModalHeader>

						<ModalBody>
							<div>
								<div className="mb-3">
									<Label for="market" className="text-secondary">Market</Label>
									<p>
										{getMarketDisplayName(market)}
									</p>
								</div>

								<div className="mb-3">
									<Label for="currentExpirationDate" className="text-secondary">Current Expiration
										Date</Label>
									<p>
										{moment(dataClone.expirationDate).format("MMM Do YYYY")}
									</p>
								</div>
							</div>

							<hr/>

							<div>
								<div className="mb-3">
									<Label>Expiration Date</Label>
									<Input type="date" value={date} placeholder="Expiration Date"
									       onChange={onDateChange}/>
								</div>
							</div>
						</ModalBody>

						<ModalFooter>
							<Button color="link" onClick={closeHelper}>
								Cancel
							</Button>

							<Button color="primary" onClick={updateSecurity}>
								Update Security
							</Button>
						</ModalFooter>
					</React.Fragment>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IEditSecurityExpirationDateModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(EditSecurityExpirationDateModal);
