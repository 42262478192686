import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Button, Card, CardBody, Col, Container, NavLink, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {AdminApi, Article, ArticleMarket, RealestateSecuritiesApi} from "client";
import {getConfig} from "../services/clientApis";
import ArticleFormModal from "../components/ArticleForm";
import ArticlePreview from "../components/ArticlePreview";

interface IArticlesPageProps {
	token?: string;
	dispatch?: any;
}

const ArticlesPage: React.FC<IArticlesPageProps> = (props: IArticlesPageProps) => {

	const history = useHistory();
	const {token, dispatch} = props;
	const [articles, setArticles] = useState<Array<Article>>([]);
	const [showAddNewArticleModal, setShowAddNewArticleModal] = useState(false);
	const [selectedArticleForEditing, setSelectedArticleForEditing] = useState<Article>();

	/**
	 * Navigation helper
	 *
	 */
	function goToMarkets(e): void {
		e.preventDefault();
		history.push("real-estate-article-markets");
	}

	useEffect(() => {
		getArticles().then().catch();
	}, []);

	/**
	 * Respond to an article being selected, in which case show the "add" modal,
	 * which gets recycled for editing
	 *
	 */
	useEffect(() => {
		if (selectedArticleForEditing) {
			setShowAddNewArticleModal(true);
		}
	}, [JSON.stringify(selectedArticleForEditing)]);

	/**
	 * Listens for the form modal closing, in which case clear the "editing"
	 * article if it exists, doing so is required to continue allowing the modal
	 * to be toggled
	 *
	 */
	useEffect(() => {
		if (!showAddNewArticleModal) {
			setSelectedArticleForEditing(null)
		}
	}, [showAddNewArticleModal]);

	/**
	 * Fetch articles from the DB
	 *
	 */
	async function getArticles(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesAllArticlesGet({});
			setArticles(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	function toggleAddNewArticleModal(): void {
		setShowAddNewArticleModal(!showAddNewArticleModal);
	}

	/**
	 * Handles finishing adding a new article - hide modal,
	 * clear editing article if one exists, & re-call get api
	 *
	 */
	async function handleFinishAddingArticleMarket(): Promise<void> {
		setShowAddNewArticleModal(false);
		setSelectedArticleForEditing(null);
		await getArticles();
	}

	/**
	 * Iterate over the articles and return react nodes for each
	 *
	 * @param arts
	 */
	function mapArticles(arts: Array<Article> = []): ReactNode {
		if (arts.length < 1) {
			return (
				<p className="text-secondary font-italic text-center w-100">
					No Articles yet.
				</p>
			);
		}

		return arts.map((a: Article, i: number) => {
			function onSelectForEditingHelper(): void {
				setSelectedArticleForEditing(a);
			}

			return (
				<Col key={`article-preview-${i}`} xs={12} lg={6} className="d-flex mb-3">
					<ArticlePreview
						article={a}
						onDoneDelete={getArticles}
						onSelectForEditing={onSelectForEditingHelper}
					/>
				</Col>
			);
		});
	}

	return (
		<React.Fragment>
			<ArticleFormModal
				open={showAddNewArticleModal}
				close={toggleAddNewArticleModal}
				onDone={handleFinishAddingArticleMarket}
				editingArticle={selectedArticleForEditing}
			/>

			<Container className="my-4">
				<div className="mb-4">
					<h1>
						Article Markets
					</h1>
					<p>
						{"Articles can exist within the main news feed, and/or in any number of the "}
						<a href="#" onClick={goToMarkets}>Real Estate Article Markets</a>.
					</p>
				</div>

				<Row className="mb-4">
					{mapArticles(articles)}
				</Row>

				<div>
					<Button color="primary" onClick={toggleAddNewArticleModal}>
						Add New Article
					</Button>
				</div>
			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IArticlesPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ArticlesPage);
