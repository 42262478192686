import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Button, Card, CardBody, Container, Input, Label} from "reactstrap";
import {
	AdminApi,
	MarketApi,
	MarketGetSecuritiesForMarketGetIsActiveEnum,
	MarketIdentifier,
	Security
} from "client";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import AddSecurityModal from "../components/AddSecurityModal";
import MarketIdentifierDropDownValues, {getMarketDisplayName} from "../components/MarketIdentifierDropDownOptions";
import DeleteSecurityModal from "../components/DeleteSecurityModal";
import {getConfig} from "../services/clientApis";
import SecuritiesTable, {ISecuritiesTableRow} from "../components/SecuritiesTable";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import EditSecurityModal from "../components/EditSecurityModal";
import EditSecurityExpirationDateModal from "../components/EditSecurityExpirationDateModal";
import UpdateSecurityRealValueModal from "../components/UpdateSecurityRealValueModal";

interface ISecurityManagementPageProps {
	token?: string;
	dispatch?: any;
}

const SecurityManagementPage: React.FC<ISecurityManagementPageProps> = (props: ISecurityManagementPageProps) => {

	const {token} = props;
	const [selectedMarket, setSelectedMarket] = useState<MarketIdentifier>(MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver);
	const [activeFilter, setActiveFilter] = useState<MarketGetSecuritiesForMarketGetIsActiveEnum>(MarketGetSecuritiesForMarketGetIsActiveEnum.Both);
	const [showAddNewSecurityModal, setShowAddNewSecurityModal] = useState(false);
	const [securityToDelete, setSecurityToDelete] = useState<Security>();
	const [securityToEdit, setSecurityToEdit] = useState<Security>();
	const [securityToEditExpirationDate, setSecurityToEditExpirationDate] = useState<Security>();
	const [securityToUpdateRealValue, setSecurityToUpdateRealValue] = useState<Security>();
	const [securities, setSecurities] = useState<Array<ISecuritiesTableRow>>([]);

	useEffect(() => {
		readSecurities().then().catch();
	}, [selectedMarket, activeFilter]);

	function toggleShowAddNewSecurityModal(getNewData: boolean = false): void {
		setShowAddNewSecurityModal(!showAddNewSecurityModal);
		if (getNewData) {
			readSecurities().then().catch();
		}
	}

	function finishDeletingSecurity(): void {
		setSecurityToDelete(null);
		readSecurities().then().catch();
	}

	function clearDeletingSecurity(): void {
		setSecurityToDelete(null);
	}

	function finishUpdatingSecurity(): void {
		setSecurityToEdit(null);
		readSecurities().then().catch();
	}

	function clearEditingSecurity(): void {
		setSecurityToEdit(null)
	}

	function finishUpdatingExpirationDate(): void {
		setSecurityToEditExpirationDate(null);
		readSecurities().then().catch();
	}

	function clearUpdatingExpirationDateSecurity(): void {
		setSecurityToEditExpirationDate(null);
	}

	function finishUpdatingRealValue(): void {
		setSecurityToUpdateRealValue(null);
		readSecurities().then().catch();
	}

	function clearUpdatingRealValueSecurity(): void {
		setSecurityToUpdateRealValue(null);
	}

	/**
	 * onChange for the market selector drop down
	 *
	 * @param e
	 */
	function onSelectedMarketChange(e): void {
		setSelectedMarket(e.target.value);
	}

	/**
	 * onChange for the active filter drop down
	 *
	 * @param e
	 */
	function onActiveFilterChange(e): void {
		setActiveFilter(e.target.value);
	}

	/**
	 * Call api and get list of securities based on selected market,
	 * called whenever the drop down is changed
	 *
	 */
	async function readSecurities(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetSecuritiesForMarketGet({
				isActive: activeFilter,
				marketIdentifier: selectedMarket,
			});

			const data: Array<ISecuritiesTableRow> = cloneDeep(res).map((s: Security): ISecuritiesTableRow => {
				return {
					raw: s,
					name: s.name,
					creationDate: moment(s.creationDate).format("MMM Do YYYY"),
					expirationDate: moment(s.expirationDate).format("MMM Do YYYY"),
					realValueAtExpiration: s.realValueAtExpiration as string,
					expectedValueAtExpiration: s.expectedValueAtExpiration as string,
					lastUpdatedValue: s.lastUpdatedValue as string,
					pastExpiration: Date.now() > s.expirationDate,
					onEdit: () => {setSecurityToEdit(s)},
					onUpdateExpirationDate: () => {setSecurityToEditExpirationDate(s)},
					onUpdateRealValue: () => {setSecurityToUpdateRealValue(s)},
					onDelete: () => {setSecurityToDelete(s)},
				}
			});

			setSecurities(data);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<AddSecurityModal
				isOpen={showAddNewSecurityModal}
				market={selectedMarket}
				onClose={toggleShowAddNewSecurityModal}
			/>

			<EditSecurityModal
				security={securityToEdit}
				market={selectedMarket}
				onFinished={finishUpdatingSecurity}
				onCancel={clearEditingSecurity}
			/>

			<EditSecurityExpirationDateModal
				security={securityToEditExpirationDate}
				market={selectedMarket}
				onFinished={finishUpdatingExpirationDate}
				onCancel={clearUpdatingExpirationDateSecurity}
			/>

			<UpdateSecurityRealValueModal
				security={securityToUpdateRealValue}
				market={selectedMarket}
				onFinished={finishUpdatingRealValue}
				onCancel={clearUpdatingRealValueSecurity}
			/>

			<DeleteSecurityModal
				security={securityToDelete}
				market={selectedMarket}
				onFinished={finishDeletingSecurity}
				onCancel={clearDeletingSecurity}
			/>

			<Container className="my-5">
				<div className="mb-4">
					<h2>Manage Securities</h2>
					<p>
						On this page you can choose a specific market from the drop-down list below to see a summary of
						the securities that exist within that market. Once a market has been selected, you can
						take edit or delete that security with the buttons in the able.

						<br/>
						<br/>

						The "Update Real Value" button only becomes available if a security is both past its expiration date, and a real value has not been set yet.
					</p>
					<div className="d-flex justify-content-center mb-3">
						<div className="w-100" style={{maxWidth: 500}}>

							<Label for="market">Select Market</Label>
							<Input type="select" id="market" name="market" placeholder="Select Market"
							       onChange={onSelectedMarketChange} value={selectedMarket}>
								<option value={-1} disabled>Select Market</option>
								<hr/>
								<MarketIdentifierDropDownValues/>
							</Input>
						</div>
					</div>

					<div className="d-flex justify-content-center mb-3">
						<div className="w-100" style={{maxWidth: 500}}>

							<Label for="activeStatus">Show securities that are:</Label>
							<Input type="select" id="activeStatus" name="activeStatus" placeholder="Select Active Status"
							       onChange={onActiveFilterChange} value={activeFilter}>
								<option value={-1} disabled>Select Active Status</option>
								<hr/>
								<option value={MarketGetSecuritiesForMarketGetIsActiveEnum.Both}>Active & Inactive (all securities)</option>
								<option value={MarketGetSecuritiesForMarketGetIsActiveEnum.True}>Active</option>
								<option value={MarketGetSecuritiesForMarketGetIsActiveEnum.False}>Inactive</option>
							</Input>
						</div>
					</div>

					<div className="d-flex justify-content-center">
						<Button color="primary" onClick={() => {toggleShowAddNewSecurityModal(false)}}>
							Add New Security
						</Button>
					</div>
				</div>

			</Container>

			<div className="my-5 px-1 px-lg-2">
				<SecuritiesTable data={securities}/>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: ISecurityManagementPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(SecurityManagementPage);
