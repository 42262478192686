import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Button, Card, CardBody, Container} from "reactstrap";
import {AdminApi, Asset, RealestateSecuritiesApi, UsefulLink} from "client";
import {getConfig} from "../services/clientApis";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import cloneDeep from "lodash.clonedeep";
import UsefulLinkForm from "../components/UsefulLinkForm";

interface IUsefulLinksPageProps {
	token?: string;
	dispatch?: any;
}

const UsefulLinksPage: React.FC<IUsefulLinksPageProps> = (props: IUsefulLinksPageProps) => {

	const {token, dispatch} = props;
	const [uls, setUls] = useState<Array<UsefulLink>>([]);
	const [assets, setAssets] = useState<Array<Asset>>([]);

	useEffect(() => {
		getUsefulLinks().then().catch();
	}, []);

	/**
	 * Call DB and get the list of useful links, as well as assets
	 *
	 */
	async function getUsefulLinks(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const assetRes = await new AdminApi(getConfig(token)).adminAssetListGet();
			setAssets(assetRes);
		} catch(e) {
			props.dispatch(addError(await e.json()));
		}

		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesUsefulLinkListGet();
			setUls(res);

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	async function submitUpdatedLinks(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesUpdateUsefulLinksPut({usefulLink: uls});

			await getUsefulLinks();
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Add a new item to the end of the array
	 *
	 */
	function addLink(): void {
		const list = cloneDeep(uls);
		list.push({});
		setUls(list);
	}

	function createOnChange(i: number, key: string, value: any): any {
		const links: Array<UsefulLink> = cloneDeep(uls);
		links[i][key] = value;
		setUls(links);
	}

	function removeLink(index: number): void {
		const links: Array<UsefulLink> = cloneDeep(uls);
		links.splice(index, 1);
		setUls(links);
	}

	/**
	 * Iterate over the useful links and return react nodes for each
	 *
	 * @param list
	 */
	function mapLinks(list: Array<UsefulLink> = []): ReactNode {
		if (list.length < 1) {
			return (
				<p className="text-secondary font-italic text-center">
					No Useful links yet.
				</p>
			)
		}

		return list.map((l: UsefulLink, i: number) => {
			function changeHelper(key: keyof UsefulLink): any {
				return (v) => {
					createOnChange(i, key, v);
				}
			}


			function removeHelper(): void {
				removeLink(i);

			}

			return (
				<Card key={`useful-link-${i}`} className="mb-3">
					<CardBody>
						<UsefulLinkForm
							form={l}
							onChange={changeHelper}
							assets={assets}
							onRemove={removeHelper}
						/>
					</CardBody>
				</Card>
			);
		});
	}

	return (
		<Container className="my-4">
			<div className="mb-4">
				<h1>
					Useful Links
				</h1>
				<p>
					When finished adding or editing links, press the "Update List" button at the bottom to save your changes.
				</p>
			</div>

			<div className="mb-4">
				{mapLinks(uls)}
			</div>

			<div className="d-flex justify-content-between">
				<Button color="primary" onClick={addLink}>
					Add New Link
				</Button>

				<Button color="success" onClick={submitUpdatedLinks}>
					Update List
				</Button>
			</div>
		</Container>
	)
};

export default connect((store: IStore, props: IUsefulLinksPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(UsefulLinksPage);
