import React, {useState} from "react";
import {Asset, UsefulLink} from "client";
import {Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import SelectOptions, {ISelectOptions} from "./SelectOptions";
import find from "lodash.find";
import {FiMenu, FiX} from "react-icons/all";
import AssetInput from "./asset_manager/AssetInput";

interface IUsefulLinkFormProps {
	form: UsefulLink;
	assets: Array<Asset>;

	onChange(key: keyof UsefulLink): any;
	onRemove(): void;
}

const UsefulLinkForm: React.FC<IUsefulLinkFormProps> = (props: IUsefulLinkFormProps) => {

	const {form, assets} = props;
	const [modalOpen, setModalOpen] = useState(false);

	function toggleRemoveModal(): void {
		setModalOpen(!modalOpen);
	}

	const assetSelectOptions: Array<ISelectOptions> = assets.map((a: Asset) => {
		return {
			value: a._id,
			text: a.name,
		}
	});

	function fieldChanges(key: keyof UsefulLink): any {
		return (e) => {
			props.onChange(key)(e.target.value);
		}
	}

	function changeAssetHelper(e): any {
		props.onChange("thumbnail")(e);
	}

	function remove(): void {
		setModalOpen(false);

		setTimeout(() => {
			props.onRemove();
		}, 150);
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={modalOpen}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={toggleRemoveModal}
			>
				<ModalHeader toggle={toggleRemoveModal}>Really Remove this useful link?</ModalHeader>

					<div className="d-flex justify-content-between py-3">
						<Button color="link" onClick={toggleRemoveModal}>
							No, don't remove
						</Button>

						<Button color="danger" onClick={remove}>
							Yes, remove
						</Button>
					</div>
			</Modal>

			<div>
				<div className="d-flex justify-content-end mb-2">
					<a className="text-danger" href="#" onClick={toggleRemoveModal}>
						<FiX size="1.75rem" style={{maxHeight: 50}}/>
					</a>
				</div>

				{form.thumbnail && (
					<div className="d-flex justify-content-center mb-3">
						<img
							src={form.thumbnail.url}
							style={{
								maxWidth: "100%",
								maxHeight: 400,
								borderRadius: 5,
							}}
						/>
					</div>
				)}

				<Row>
					<Col xs={12} className="mb-3">
						<Label>Thumbnail</Label>
						<AssetInput value={form.thumbnail} onChange={changeAssetHelper}/>
					</Col>

					<Col xs={12} sm={6} className="mb-3">
						<Label>Useful Link URL</Label>
						<Input
							type="text"
							name="link-url"
							placeholder="Link URL"
							value={form.usefulURL}
							onChange={fieldChanges("usefulURL")}
						/>
					</Col>

					<Col xs={12} sm={6} className="mb-3">
						<Label>Title</Label>
						<Input
							type="text"
							name="link-title"
							placeholder="Link Title"
							value={form.title}
							onChange={fieldChanges("title")}
						/>

					</Col>

					<Col xs={12} className="mb-3">
						<Label>Description</Label>
						<Input
							type="textarea"
							name="link-description"
							placeholder="Link Description"
							value={form.description}
							onChange={fieldChanges("description")}
						/>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	)
};

UsefulLinkForm.defaultProps = {
	form: {},
	assets: [],
};

export default UsefulLinkForm;
