import React, {useState} from "react";
import {Asset} from "client";
import AssetManagerModal from "./AssetManagerModal";
import {Button, Input, Label} from "reactstrap";

interface IProps {
	value: Asset;
	onChange: (asset: Asset) => void;
}

const AssetInput: React.FC<IProps> = ({value, onChange}) => {

	const [open, setOpen] = useState<boolean>(false);

	function openModal() {
		setOpen(true);
	}

	function closeModal() {
		setOpen(false);
	}

	function selectAndClose(asset: Asset):  void {
		onChange(asset);
		setOpen(false);
	}

	return (
		<>
			<AssetManagerModal open={open} close={closeModal} onSelect={selectAndClose}/>
			<div className="flex-column">
				{value && <div><a href={value.url} target="_blank">{value.name}</a></div>}
				<Button className="btn-sm" color="primary" onClick={openModal}>{!value ? "Select Asset" : "Change Asset"}</Button>
			</div>
		</>
	);
};

export default AssetInput;
