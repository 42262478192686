import {MarketIdentifier} from "client";

export function marketIdentifierParser(m: MarketIdentifier): string {
	switch(m) {
		case MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver:
			return "Real Estate Securities Play Money Vancouver";
		default:
			return m;
	}
}
