import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./style/index.scss";
import {BrowserRouter} from "react-router-dom";
import {persistor, store} from "./services/redux";
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<App/>
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

serviceWorker.unregister();
