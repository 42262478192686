import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Button, Container} from "reactstrap";
import UserTable from "../components/UserTable";
import {AdminApi, User} from "client";
import {getConfig} from "../services/clientApis";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import AddNewUserModal from "../components/AddNewUserModal";

interface IProps {
	token?: string;
	dispatch?: any;
}

const UserPage: React.FC<IProps> = (props) => {

	const [users, setUsers] = useState<User[]>([]);
	const [createNewUserOpen, setNewUserOpen] = useState(false);

	useEffect(() => {
		updateUsers().then().catch();
	}, []);

	async function updateUsers() {
		props.dispatch(incrementLoading());
		try {
			const res = await new AdminApi(getConfig(props.token)).adminUserListGet();
			setUsers(res);
		} catch (err) {
			props.dispatch(addError(err));
		}
		props.dispatch(decrementLoading());
	}

	const closeAddNewUserModal = () => setNewUserOpen(false);
	const openAddNewUserModal = () => setNewUserOpen(true);

	return (
		<React.Fragment>
			<Container className="my-4">
				<div className="mb-4">
					<h1>
						Users
					</h1>
				</div>

				<UserTable data={users} onUpdate={updateUsers}/>
			</Container>
		</React.Fragment>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(UserPage);
