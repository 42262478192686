import React from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Spinner} from "reactstrap";

interface ILoaderProps {
	loadingCount?: number;
}

const Loader: React.FC<ILoaderProps> = (props: ILoaderProps) => {

	const {loadingCount} = props;

	return (
		<React.Fragment>
			{loadingCount > 0 && (
				<div
					style={{
						height: "100vh",
						width: "100vw",
						position: "fixed",
						zIndex: 10000,
						backgroundColor: "rgba(0, 0, 0, 0.5)",
					}}
					className="d-flex justify-content-center align-items-center"
				>
					<Spinner color="light"/>
				</div>
			)}
		</React.Fragment>
	);
};

export default connect((store: IStore, props: ILoaderProps) => {
	return {
		loadingCount: store.metaStore.loadingCount,
		...props,
	}
})(Loader);
