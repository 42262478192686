import storage from "redux-persist/lib/storage";
import {AnyAction, applyMiddleware, combineReducers, createStore, Reducer, Store, StoreEnhancer} from "redux";
import {persistStore, persistReducer} from "redux-persist";
import logger from "redux-logger";
import defaultStore, {IStore} from "./defaultStore";
import MetaReducer from "./meta/MetaReducer";
import promiseMiddleware from "redux-promise";

const persistConfigMeta = {
	key: "meta",
	storage,
	whitelist: ["token"],
};

const reducers: Reducer<any, any> = combineReducers({
	metaStore: persistReducer(persistConfigMeta, MetaReducer)
});

const middleware: StoreEnhancer = applyMiddleware(logger, promiseMiddleware);

export const store: Store<IStore> = createStore(reducers, defaultStore, middleware);
export const persistor = persistStore(store);

export interface IAction<T = any, P = undefined> extends AnyAction {
	type: T,
	payload?: P,
}
