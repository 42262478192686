import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import React, {useEffect, useState} from "react";
import {AdminApi, Asset} from "client";
import {connect} from "react-redux";
import {addError} from "../../services/redux/meta/MetaActions";
import {getConfig} from "../../services/clientApis";
import {IStore} from "../../services/redux/defaultStore";
import AssetListItem from "./AssetListItem";
import AssetUploadModal from "./AssetUploadModal";

interface IProps {
	open: boolean;
	close: () => void;
	onSelect?: (asset: Asset) => void;
	dispatch?: any;
	token?: string;
}

const AssetManagerModal: React.FC<IProps> = ({open, close, onSelect, dispatch, token}) => {

	const [assetList, setAssetList] = useState<Asset[]>();
	const [loading, setLoading] = useState<boolean>(true);
	const [uploadAssetIsOpen, setUploadAssetIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (open) {
			getList().then().catch();
		} else {
			setAssetList(undefined);
		}
	}, [open])

	async function getList() {
		setLoading(true);
		try {
			const res = await new AdminApi(getConfig(token)).adminAssetListGet();
			setAssetList(res);
		} catch (err) {
			dispatch(addError(err))
		}
		setLoading(false);
	}

	function openUploadAsset() {
		setUploadAssetIsOpen(true);
	}

	function closeUploadAsset() {
		setUploadAssetIsOpen(false);
	}

	function createAssetListItem(asset: Asset, i: number, arr) {
		return (
			<>
				<AssetListItem asset={asset} key={"asset_" + i} onSelect={onSelect} onDelete={getList} onUpdate={getList}/>
				{(i !== arr.length - 1) && <hr key={"asset_divider_" + i}/>}
			</>
		)
	}


	return (
		<>
			<AssetUploadModal open={uploadAssetIsOpen} close={closeUploadAsset} onUpload={getList}/>
			<Modal isOpen={open} centered={true} toggle={close}>
				<ModalHeader toggle={close}>Asset Manager</ModalHeader>
				<ModalBody style={{height: "650px", overflow: "scroll"}}>
					{
						loading ?
							<div className="d-flex justify-content-center align-items-center">
								<Spinner/>
							</div> :
							<>
								{assetList && assetList.map(createAssetListItem)}
							</>
					}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={openUploadAsset}>Upload New Asset</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(AssetManagerModal)