import {connect} from "react-redux";
import React, {useState} from "react";
import {AdminApi, Asset} from "client";
import {IStore} from "../../services/redux/defaultStore";
import {Button, ButtonDropdown, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import FileViewer from 'react-file-viewer';
import mime from "mime-types";
import {addError, decrementLoading, incrementLoading} from "../../services/redux/meta/MetaActions";
import {getConfig} from "../../services/clientApis";

interface IProps {
	asset: Asset;
	onSelect?: (asset: Asset) => void;
	onDelete?: (asset: Asset) => void;
	onUpdate?: () => void;
	token?: string;
	dispatch?: any;
}

const AssetListItem: React.FC<IProps> = (props) => {

	const {asset, onSelect, onDelete, onUpdate, token, dispatch} = props;

	const [showDelete, setShowDelete] = useState<boolean>(false);

	function deleteStepOne() {
		setShowDelete(true)
	}

	function closeDeleteStepOne() {
		setShowDelete(false)
	}

	async function deleteAsset() {
		dispatch(incrementLoading());

		try {
			await new AdminApi(getConfig(token)).adminAssetDelete({asset})
			if (onDelete) {onDelete(asset)}
		} catch (err) {
			dispatch(addError(err))
		}

		closeDeleteStepOne();
		dispatch(decrementLoading());
	}

	function onSelectWrapper() {
		onSelect(asset);
	}

	return (
		<>
			{/*Delete Confirmation*/}
			<Modal isOpen={showDelete} centered={true} toggle={closeDeleteStepOne}>
				<ModalHeader toggle={closeDeleteStepOne}>Are You Sure?</ModalHeader>
				<ModalBody>By deleting this asset, it will be removed entirely from the system. If this asset is linked anywhere else in the application, the asset will <b>NOT</b> automatically be removed from those references. Please make sure this asset isn't being used anywhere before deleting.</ModalBody>
				<ModalFooter>
					<Button onClick={closeDeleteStepOne}>Cancel</Button>
					<Button color="danger" onClick={deleteAsset}>Delete</Button>
				</ModalFooter>
			</Modal>

			{/*List Item*/}
			<div className="d-flex justify-content-between align-items-center flex-row">
				{/*<FileViewer*/}
				{/*	key={asset.name}*/}
				{/*	fileType={mime.extension(asset.mimeType)}*/}
				{/*	filePath={asset.url}*/}
				{/*	errorComponent={null}*/}
				{/*/>*/}
				<div className="d-flex flex-column">
					<a className="mb-1" target="_blank" href={asset.url}>{asset.name}</a>
					<ButtonGroup>
						{onSelect && <Button color="primary" onClick={onSelectWrapper}>Select</Button>}
						<Button color="primary">Update</Button>
						<Button color="danger" onClick={deleteStepOne}>Delete</Button>
					</ButtonGroup>
				</div>
			</div>
		</>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(AssetListItem)