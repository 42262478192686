import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Button, Input, Label, Modal, ModalFooter, ModalHeader} from "reactstrap";
import {Admin, AdminApi, AdminRole, CreateAdminBody} from "client";
import SelectOptions from "./SelectOptions";
import {getConfig} from "../services/clientApis";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";

interface IAddNewAdminModalProps {
	token?: string;
	dispatch?: any;
	open: boolean;
	close(): void;
	onDone(): Promise<void>;
}

const defaultAdminForm: CreateAdminBody = {
	username: "",
	password: "",
	confirmPassword: "",
	firstName: "",
	lastName: "",
	email: "",
	role: "" as AdminRole,
};

const AddNewAdminModal: React.FC<IAddNewAdminModalProps> = (props: IAddNewAdminModalProps) => {

	const {token, open} = props;
	const [form, setForm] = useState<CreateAdminBody>(defaultAdminForm);

	function closeHelper(): void {
		setForm(defaultAdminForm);
		props.close();
	}

	function createOnChange(key: keyof CreateAdminBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	async function submitNewAdmin(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new AdminApi(getConfig(token)).adminFormsCreateAdminPost({createAdminBody: form});

			props.dispatch(decrementLoading());
			await props.onDone();
		} catch(e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<Modal
			isOpen={open}
			fade={true}
			centered={true}
			contentClassName="px-3"
			toggle={closeHelper}
		>
			<ModalHeader toggle={closeHelper}>Add New Admin</ModalHeader>

			<div className="my-3">
				<Label>Username*</Label>
				<Input value={form.username} placeholder="Username" onChange={createOnChange("username")} className="mb-3"/>

				<Label>Password*</Label>
				<Input type="password" value={form.password} placeholder="Password" onChange={createOnChange("password")} className="mb-3"/>

				<Label>Confirm Password*</Label>
				<Input type="password" value={form.confirmPassword} placeholder="Confirm Password" onChange={createOnChange("confirmPassword")} className="mb-3"/>

				<Label>Role*</Label>
				<Input type="select" value={form.role} placeholder="Role" onChange={createOnChange("role")} className="mb-3">
					<option value="" selected disabled>Select a Role</option>
					<hr/>
					<SelectOptions strings={Object.values(AdminRole)}/>
				</Input>

				<Label>First Name</Label>
				<Input value={form.firstName} placeholder="First Name" onChange={createOnChange("firstName")} className="mb-3"/>

				<Label>Last Name</Label>
				<Input value={form.lastName} placeholder="Last Name" onChange={createOnChange("lastName")} className="mb-3"/>

				<Label>Email</Label>
				<Input value={form.email} placeholder="Email" onChange={createOnChange("email")} className="mb-3"/>
			</div>

			<ModalFooter>
				<Button color="primary" onClick={submitNewAdmin}>
					Confirm
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IAddNewAdminModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(AddNewAdminModal);
