import React, {ReactNode} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {Security, TradingProfile, User} from "client";
import {Button} from "reactstrap";
import {formatCurrency} from "../utils/formatters";

export interface ISecuritiesTableRow {
	raw: Security,
	name: string;
	creationDate: string;
	expirationDate: string;
	realValueAtExpiration: string;
	expectedValueAtExpiration: string;
	lastUpdatedValue: string;
	pastExpiration: boolean;
	onEdit(_s: Security): void;
	onUpdateExpirationDate(_s: Security): void;
	onUpdateRealValue(_s: Security): void;
	onDelete(_s: Security): void;
}

interface ISecuritiesTableProps {
	data: Array<ISecuritiesTableRow>;
}

const SecuritiesTable: React.FC<ISecuritiesTableProps> = (props: ISecuritiesTableProps) => {

	const {data} = props;

	function createNoDataMessage(): ReactNode {
		return (
			<div className="my-2">
				<p className="text-center">
					No Securities
				</p>
			</div>
		)
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			columns={[
				{
					dataField: "name",
					text: "Name",
					sort: true,
				},
				{
					dataField: "creationDate",
					text: "Creation Date",
					sort: true,
				},
				{
					dataField: "expirationDate",
					text: "Expiration Date",
					sort: true,
				},
				{
					dataField: "pastExpiration",
					text: "Status",
					formatter: formatPastExpiration,
					sort: true,
					classes: (c) => c ? "text-danger" : "text-success",
				},
				{
					dataField: "realValueAtExpiration",
					text: "Real Value at Expiration",
					formatter: formatCurrency,
					sort: true,
				},
				{
					dataField: "expectedValueAtExpiration",
					text: "Expected Value at Expiration",
					formatter: formatCurrency,
					sort: true,
				},
				{
					dataField: "lastUpdatedValue",
					text: "Last Updated Value",
					formatter: formatCurrency,
					sort: true,
				},
				{
					dataField: "deleteButton",
					text: "Actions",
					formatter: makeManageButtons,
				}
			]}
			keyField={"key"}
			data={
				data.filter((d) => {
					for (let k in d) {
						if (!d.hasOwnProperty(k)) {continue}
						if ((typeof d[k] === "string" || typeof d[k] === "undefined") && (!d[k] || d[k].length < 1)) {
							d[k] = "-"
						}
					}
					return d;
				}).map((d: any, i: number) => {
					return {...d, key: i}
				})
			}
			noDataIndication={createNoDataMessage}
			defaultSorted={[{dataField: "name", order: "asc"}]}
		/>
	);
};

function formatPastExpiration(past: boolean): string {
	return past ? "Expired" : "Active";
}

function makeManageButtons(cell: string, row: ISecuritiesTableRow): ReactNode {

	function onEditHelper(): void {
		row.onEdit(row.raw);
	}

	function onUpdateExpirationDateHelper(): void {
		row.onUpdateExpirationDate(row.raw);
	}

	function onUpdateRealValue(): void {
		row.onUpdateRealValue(row.raw);
	}

	function onDeleteHelper(): void {
		row.onDelete(row.raw);
	}

	return (
		<div className="d-flex">
			<Button onClick={onEditHelper} color="primary" className="mr-3">
				Edit Security
			</Button>

			<Button onClick={onUpdateExpirationDateHelper} color="primary" className="mr-3">
				Update Expiration Date
			</Button>

			<Button onClick={onUpdateRealValue} disabled={!row.pastExpiration || (row.raw.realValueAtExpiration !== undefined)} color="primary" className="mr-3">
				Update Real Value
			</Button>

			<Button onClick={onDeleteHelper} color="danger" className="mr-3">
				Delete Security
			</Button>

		</div>
	);
}

export default SecuritiesTable;
