import React from 'react';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import Login from "./pages/Login";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import NotFound from "./pages/NotFound";
import ErrorModalHandler from "./components/ErrorModalHandler";
import Dashboard from "./pages/Dashboard";
import {connect} from "react-redux";
import {IStore} from "./services/redux/defaultStore";
import {PersistGate} from "redux-persist/integration/react";
import {persistor} from "./services/redux";
import SidebarHelper from "./components/sidebar/SidebarHelper";
import ManageAdmins from "./pages/ManageAdmins";
import Loader from "./components/Loader";
import UsefulLinks from "./pages/UsefulLinks";
import ArticlesPage from "./pages/Articles";
import RealEstateMarketsPage from "./pages/RealEstateMarketsPage";
import SecurityManagementPage from "./pages/SecurityManagementPage";
import ManageTradingProfilesPage from "./pages/ManageTradingProfilesPage";
import ContentEditing from "./pages/ContentEditing";
import UnverifiedPaymentsPage from "./pages/UnverifiedPaymentsPage";
import RealEstateFAQPage from "./pages/RealEstateFAQPage";
import UpdateExpiredSecurityRealValuesPage from "./pages/UpdateExpiredSecurityRealValuesPage";
import UserPage from "./pages/UserPage";
import TokenManager from "./components/TokenManager";

interface IProps {
	loadingCount?: number
}

const App: React.FC<IProps> = ({loadingCount}) => {

	/* Header component moved to JSX of SidebarHelper.tsx to make it work how we wanted; hidden on login/404 pages */

	return (
		<PersistGate persistor={persistor}>
			<BrowserRouter>

				<ErrorModalHandler/>
				<Loader/>
				<TokenManager/>

				<SidebarHelper>
					<main className="mh-100">
						<Switch>
							<Route exact path="/" component={Login}/>

							<AuthenticatedRoute exact path="/dashboard" component={Dashboard}/>
							<AuthenticatedRoute exact path="/admin-manager" component={ManageAdmins}/>
							<AuthenticatedRoute exact path="/user-manager" component={UserPage}/>
							<AuthenticatedRoute exact path="/content-editing" component={ContentEditing}/>

							<AuthenticatedRoute exact path="/security-management" component={SecurityManagementPage}/>
							<AuthenticatedRoute exact path="/trading-profiles" component={ManageTradingProfilesPage}/>
							<AuthenticatedRoute exact path="/update-security-real-values" component={UpdateExpiredSecurityRealValuesPage}/>
							<AuthenticatedRoute exact path="/unverified-payments" component={UnverifiedPaymentsPage}/>
							<AuthenticatedRoute exact path="/real-estate-faq" component={RealEstateFAQPage}/>

							<AuthenticatedRoute exact path="/useful-links" component={UsefulLinks}/>
							<AuthenticatedRoute exact path="/articles" component={ArticlesPage}/>
							<AuthenticatedRoute exact path="/real-estate-article-markets" component={RealEstateMarketsPage}/>

							<Route exact path="/404" component={NotFound}/>
							<Redirect to="/404"/>
						</Switch>
					</main>

				</SidebarHelper>
			</BrowserRouter>


		</PersistGate>
	);
};

export default connect((store: IStore, props: IProps) => {
	return ({
		...props,
		loadingCount: store.metaStore.loadingCount
	})
})(App);
