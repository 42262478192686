import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {
	AdminApi,
	MarketApi,
	MarketIdentifier,
	Order,
	Portfolio,
	PortfolioOrder,
	PortfolioSecurity,
	TradingProfile, Transaction
} from "client";
import cloneDeep from "lodash.clonedeep";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";
import NumberFormat from "react-number-format";
import {formatCurrency} from "../utils/formatters";
import values from "lodash.values";
import HoldingsBreakdownTable, {IHoldingsBreakdownTableRow} from "./HoldingsBreakdownTable";
import moment from "moment";
import HistoryOfOrdersTable, {IHistoryOfOrdersTableRow} from "./HistoryOfOrdersTable";
import UsersTransactionsTable, {IUserTransactionTableRow} from "./UsersTransactionsTable";
import TransactionSummaryModal from "./TransactionSummaryModal";

interface IViewPortfolioModalProps {
	token?: string;
	dispatch?: any;
	tp: TradingProfile;
	market: MarketIdentifier;

	onClose(getNewData: boolean): void;
}

const ViewPortfolioModal: React.FC<IViewPortfolioModalProps> = (props: IViewPortfolioModalProps) => {

	const {token, tp, market} = props;
	const [dataClone, setDataClone] = useState<TradingProfile>();
	const [portfolio, setPortfolio] = useState<Portfolio>();
	const [transactionToView, setTransactionToView] = useState<Transaction>();

	useEffect(() => {
		if (tp && Object.keys(tp).length > 0) {
			readPortfolio().then().catch();
			setDataClone(cloneDeep(tp))
		}
	}, [JSON.stringify(tp)]);

	function closeHelper(): void {
		props.onClose(false);
	}

	async function readPortfolio(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketAdminGetPortfolioGet({
				id: tp._id,
			});

			setPortfolio(res);

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	function createHoldings(holdings: { [key: string]: PortfolioSecurity }): ReactNode {

		const holdingsData: Array<IHoldingsBreakdownTableRow> = values(holdings).map((s: PortfolioSecurity): IHoldingsBreakdownTableRow => {
			const total: number = s.trueOwned + s.falseOwned + s.trueInvested + s.falseInvested;

			return {
				raw: s,
				propertyType: s.security.name,
				expirationDate: moment(s.security.expirationDate).format("MMM Do YYYY"),
				priceAtExpiration: s.security.expectedValueAtExpiration as number,
				aboveMarketAvailable: s.trueOwned,
				belowMarketAvailable: s.falseOwned,
				aboveMarketInvested: s.trueInvested,
				belowMarketInvested: s.falseInvested,
				total,
			}
		});

		return (
			<div className="border-muted border manage-profiles-view-portfolio-table">
				<HoldingsBreakdownTable data={holdingsData}/>
			</div>
		);
	}

	function createOrders(orders: { [key: string]: PortfolioOrder }): ReactNode {

		const orderData: Array<IHistoryOfOrdersTableRow> = values(orders).map((o: PortfolioOrder): IHistoryOfOrdersTableRow => {
			return {
				raw: o,
				listingType: o.order.orderType,
				propertyType: o.order.security.name,
				// date: moment(o.order.transactions[0].date).format("MMM Do YYYY"),
				amountLeft: o.remainingAmount,
				initialAmount: o.order.initialQuantity,
				pricePerSecurity: o.order.pricePer,
				// moneyTransferred: o.order.moneyTransferredSoFar,
				active: o.order.active,
				expectedTrue: o.order.expectedTrue,
			}
		});

		return (
			<div className="border-muted border manage-profiles-view-portfolio-table">
				<HistoryOfOrdersTable data={orderData}/>
			</div>
		);
	}

	function createTransactions(ts: Array<Transaction>): ReactNode {

		const transactionData: Array<IUserTransactionTableRow> = values(ts).map((t: Transaction): IUserTransactionTableRow => {

			function view(t: Transaction): void {
				setTransactionToView(t);
			}

			return {
				raw: t,
				date: moment(t.date).format("MMM Do YYYY"),
				transactionType: t.transactionType,
				onViewDetails: view,
			}
		});

		return (
			<div className="border-muted border manage-profiles-view-portfolio-table">
				<UsersTransactionsTable data={transactionData}/>
			</div>
		);
	}

	function closeTransactionViewer(): void {
		setTransactionToView(null);
	}

	return (
		<React.Fragment>
			<TransactionSummaryModal
				transaction={transactionToView}
				onClose={closeTransactionViewer}
			/>

			<Modal
				isOpen={tp && Object.keys(tp).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				className="modal-max-1800"
				toggle={closeHelper}
			>
				<ModalHeader toggle={closeHelper}>Viewing Portfolio</ModalHeader>

				{dataClone && portfolio && (
					<ModalBody>
						<p>
							{`You are viewing the portfolio of the  user with email: "${dataClone.parentUser["email"]}" in the ${getMarketDisplayName(market)} market.`}
						</p>

						<div>
							<Label for="availableMoney" className="text-secondary">Available Money</Label>
							<p>
								{formatCurrency(portfolio.availableMoney)}
							</p>
						</div>

						<div>
							<Label for="investedMoney" className="text-secondary">Invested Money (Amount invested in
								active buy orders)</Label>
							<p>
								{formatCurrency(portfolio.investedMoney)}
							</p>
						</div>

						<div className="mb-5">
							<Label for="holdings" className="text-secondary">Holdings (Table can be scrolled vertically
								when greater than 5 rows)</Label>
								{createHoldings(portfolio.securities)}
						</div>

						<div className="mb-5">
							<Label for="holdings" className="text-secondary">Current Orders (Table can be scrolled
								vertically when greater than 5 rows)</Label>
								{createOrders(portfolio.orders)}
						</div>

						<div className="mb-5">
							<Label for="holdings" className="text-secondary">Transactions (Table can be scrolled
								vertically when greater than 5 rows)</Label>
								{createTransactions(portfolio.transactions)}
						</div>

					</ModalBody>
				)}

				<ModalFooter>
					<Button color="primary" onClick={closeHelper}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IViewPortfolioModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ViewPortfolioModal);
