import React, {ReactNode} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {UnverifiedPayout} from "client";
import {decimalAsPercent, formatCurrency, numberWithCommas} from "../utils/formatters";
import moment, {Duration} from "moment";
import {Button} from "reactstrap";

export interface IUnverifiedPaymentTableRow extends UnverifiedPayout {
	raw: UnverifiedPayout,
	onViewPortfolio(_u: UnverifiedPayout): void;
	onVerifyPayment(_u: UnverifiedPayout): void;
}

interface IUnverifiedPaymentsTableProps {
	data: Array<IUnverifiedPaymentTableRow>;
}

const UnverifiedPaymentsTable: React.FC<IUnverifiedPaymentsTableProps> = (props: IUnverifiedPaymentsTableProps) => {

	const {data} = props;

	function createNoDataMessage(): ReactNode {
		return (
			<div className="my-2">
				<p className="text-center">
					No Unverified Payments
				</p>
			</div>
		);
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			columns={[
				{
					dataField: "expiredSecurity[name]",
					text: "Security",
					sort: true,
				},
				{
					dataField: "expiredSecurity[creationDate]",
					text: "Security Created",
					sort: true,
					formatter: (c) => moment(c).format("MMM Do YYYY"),
				},
				{
					dataField: "expiredSecurity[expirationDate]",
					text: "Security Expired",
					sort: true,
					formatter: (c) => moment(c).format("MMM Do YYYY"),
				},
				{
					dataField: "pendingTime",
					text: "Time since expiry",
					sort: true,
					formatter: (c) => {
						const d: Duration = moment.duration(c);
						return d.days() + " Day(s), " + d.hours() + " Hour(s)"
					},
				},
				{
					dataField: "amountOfSecurityOwned",
					text: "Amount of security owned",
					formatter: numberWithCommas,
					sort: true,
					classes: "text-right",
				},
				{
					dataField: "marketShare",
					text: "Market Share",
					formatter: decimalAsPercent,
					sort: true,
					classes: "text-right",

				},
				{
					dataField: "amountToBePaidOut",
					text: "Amount to be paid out",
					formatter: formatCurrency,
					sort: true,
					classes: "text-right",
				},
				{
					dataField: "actions",
					text: "Actions",
					formatter: makeButtons,
					sort: true,
				},
			]}
			keyField={"key"}
			data={
				data.filter((d) => {
					for (let k in d) {
						if (!d.hasOwnProperty(k)) {continue}
						if ((typeof d[k] === "string" || typeof d[k] === "undefined") && (!d[k] || d[k].length < 1)) {
							d[k] = "-"
						}
					}
					return d;
				}).map((d: IUnverifiedPaymentTableRow, i: number) => {
					return {...d, key: i}
				})
			}
			noDataIndication={createNoDataMessage}
			defaultSorted={[{dataField: "parentUser[email]", order: "asc"}]}
		/>
	);
};

function makeButtons(cell: string, row: IUnverifiedPaymentTableRow): ReactNode {

	function onViewHelper(): void {
		row.onViewPortfolio(row.raw);
	}

	function onVerifyHelper(): void {
		row.onVerifyPayment(row.raw);
	}

	return (
		<div className="d-flex">
			<Button onClick={onViewHelper} color="primary" className="mr-3">
				View Portfolio
			</Button>

			<Button onClick={onVerifyHelper} color="success" className="mr-3">
				Verify Payment
			</Button>
		</div>
	);
}

export default UnverifiedPaymentsTable;
