import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {AdminApi, ArticleMarket, Asset, CreateAdminBody, RealestateSecuritiesApi} from "client";
import {Button, Input, Label, Modal, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import SelectOptions, {ISelectOptions} from "./SelectOptions";
import find from "lodash.find";
import AssetInput from "./asset_manager/AssetInput";

interface IAddNewArticleMarketModalProps {
	token?: string;
	dispatch?: any;
	open: boolean;
	editingArticleMarket?: ArticleMarket;
	close(): void;
	onDone(): Promise<void>;
}

const defaultArticleMarket: ArticleMarket = {
	title: "",
	thumbnail: null,
};

const AddNewArticleMarketModal: React.FC<IAddNewArticleMarketModalProps> = (props: IAddNewArticleMarketModalProps) => {

	const {token, open, editingArticleMarket} = props;
	const [form, setForm] = useState<ArticleMarket>(editingArticleMarket ? editingArticleMarket : defaultArticleMarket);
	const [assets, setAssets] = useState<Array<Asset>>([]);

	useEffect(() => {
		getAssets().then().catch();
	}, []);

	useEffect(() => {
		if (props.editingArticleMarket) {
			setForm(props.editingArticleMarket);
		} else {
			setForm(defaultArticleMarket)
		}
	}, [JSON.stringify(props.editingArticleMarket)]);

	/**
	 * Call DB to get list of assets for the thumbnail selection
	 *
	 */
	async function getAssets(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const assetRes = await new AdminApi(getConfig(token)).adminAssetListGet();
			setAssets(assetRes);
		} catch(e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Reset form & close modal
	 *
	 */
	function closeHelper(): void {
		setForm(defaultArticleMarket);
		props.close();
	}

	/**
	 * onChange for standard input fields
	 *
	 * @param key
	 */
	function createOnChange(key: keyof ArticleMarket): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Custom onChange for drop down
	 *
	 * @param e
	 */
	function changeAssetHelper(e): any {
		setForm({
			...form,
			thumbnail: e,
		})
	}

	/**
	 * Attempt to save the new Article Market, call props.onDone() on success
	 *
	 */
	async function submitNewArticleMarket(): Promise<void> {
		props.dispatch(incrementLoading());

		if (props.editingArticleMarket) {
			try {
				const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesArticleMarketPut({articleMarket: form});
				props.dispatch(decrementLoading());
				await props.onDone();
			} catch(e) {
				props.dispatch(decrementLoading());
				props.dispatch(addError(await e.json()));
			}

		} else {
			try {
				const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesArticleMarketPost({articleMarket: form});
				props.dispatch(decrementLoading());
				await props.onDone();
			} catch(e) {
				props.dispatch(decrementLoading());
				props.dispatch(addError(await e.json()));
			}
		}

	}

	const assetSelectOptions: Array<ISelectOptions> = assets.map((a: Asset) => {
		return {
			value: a._id,
			text: a.name,
		}
	});

	return (
		<Modal
			isOpen={open}
			fade={true}
			centered={true}
			contentClassName="px-3"
			toggle={closeHelper}
		>
			<ModalHeader toggle={closeHelper}>Add New Article Market</ModalHeader>

			<div className="my-3">
				{form.thumbnail && (
					<div className="d-flex justify-content-center mb-3">
						<img
							src={form.thumbnail.url}
							style={{
								maxWidth: "100%",
								maxHeight: 300,
								borderRadius: 5,
							}}
						/>
					</div>
				)}

				<div className="mb-3">
					<Label>Thumbnail*</Label>
					<AssetInput value={form.thumbnail} onChange={changeAssetHelper}/>
				</div>

				<Label>Title*</Label>
				<Input value={form.title} placeholder="Article Market Title" onChange={createOnChange("title")} className="mb-3"/>
			</div>

			<ModalFooter>
				<Button color="primary" onClick={submitNewArticleMarket}>
					Submit Article Market
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IAddNewArticleMarketModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(AddNewArticleMarketModal);
