import React, {ReactNode} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {IColumn} from "../utils/tableCellHTMLFormatters";
import {Transaction} from "client";
import {Button} from "reactstrap";

export interface IUserTransactionTableRow {
	raw: Transaction;
	date: string;
	transactionType: string;
	onViewDetails(raw: Transaction): void;
}

interface IUsersTransactionsTableProps {
	data: Array<IUserTransactionTableRow>;
}

const UsersTransactionsTable: React.FC<IUsersTransactionsTableProps> = (props: IUsersTransactionsTableProps) => {

	const {data} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			hover={true}
			keyField="id"
			data={data}
			columns={columns}
			bordered={false}
			noDataIndication="User has no order history yet."
			defaultSorted={[{dataField: "date", order: "desc"}]}
		/>
	);
};

const columns: Array<IColumn> = [
	{dataField: "date", text: "Date", sort: true},
	{dataField: "transactionType", text: "Transaction Type", sort: true},
	{dataField: "action", text: "", formatter: createViewFullDetailButton},
];

function createViewFullDetailButton(cell: string, row: IUserTransactionTableRow): ReactNode {

	function onViewHelper(): void {
		row.onViewDetails(row.raw);
	}

	return (
		<div className="d-flex justify-content-end">
			<Button onClick={onViewHelper} color="primary" className="mr-3">
				View Details
			</Button>
		</div>
	);
}

export default UsersTransactionsTable;
