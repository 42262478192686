import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {AdminApi, MarketApi, MarketIdentifier, Order, TradingProfile} from "client";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import NumberFormat from "react-number-format";
import cloneDeep from "lodash.clonedeep";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import GenericSuccessModal from "./GenericSuccessModal";
import {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";

interface IGiftMoneyModalProps {
	token?: string;
	dispatch?: any;
	tp: TradingProfile;
	market: MarketIdentifier;

	onClose(getNewData: boolean): void;
}

const defaultGiftMoneyForm: any = {};

const GiftMoneyModal: React.FC<IGiftMoneyModalProps> = (props: IGiftMoneyModalProps) => {

	const {token, tp, market} = props;
	const [dataClone, setDataClone] = useState<TradingProfile>();
	const [form, setForm] = useState<{ amount: number }>(defaultGiftMoneyForm);
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (tp && Object.keys(tp).length > 0) {
			setDataClone(cloneDeep(tp))
		}
	}, [JSON.stringify(tp)]);

	function closeHelper(): void {
		setForm(defaultGiftMoneyForm);
		props.onClose(false);
	}

	function onSuccessfulSubmission(): void {
		setForm(defaultGiftMoneyForm);
		setShowSuccess(false);
		props.onClose(true);
	}

	function onNumberChange(e): void {
		let v = e.floatValue;
		if (v > 10000) {
			v = 10000;
		}

		setForm({
			...form,
			amount: v,
		});
	}

	async function submitGiftMoney(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketAdminGiftMoneyPost({
				adminGiftMoneyBody: {
					tradingProfile: tp,
					monetaryChange: form.amount,
				}
			});

			setShowSuccess(true);

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onSuccessfulSubmission}
			/>

			<Modal
				isOpen={tp && Object.keys(tp).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={closeHelper}
			>
				<ModalHeader toggle={closeHelper}>Gift Money</ModalHeader>

				{dataClone && (
					<ModalBody>
						<p>
							{`You are gifting money to the user with email: "${dataClone.parentUser["email"]}" in the ${getMarketDisplayName(market)} market.`}
						</p>

						<div>
							<Label for="amount">Amount <span className="text-muted font-italic">(maximum $10,000)</span></Label>
							<NumberFormat
								prefix="$"
								allowLeadingZeros={false}
								placeholder="Gift Amount"
								value={form.amount}
								customInput={Input}
								thousandSeparator={true}
								decimalScale={2}
								onValueChange={onNumberChange}
								allowNegative={false}
							/>
						</div>
					</ModalBody>
				)}

				<ModalFooter>
					<Button color="primary" onClick={submitGiftMoney} disabled={!form.amount}>
						Gift Money
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IGiftMoneyModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(GiftMoneyModal)
