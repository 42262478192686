import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {MarketApi, MarketIdentifier, TradingProfile, UnverifiedPayout} from "client";
import {getConfig} from "../services/clientApis";
import {Container, Input, Label} from "reactstrap";
import MarketIdentifierDropDownValues from "../components/MarketIdentifierDropDownOptions";
import UnverifiedPaymentsTable, {IUnverifiedPaymentTableRow} from "../components/UnverifiedPaymentsTable";
import cloneDeep from "lodash.clonedeep";
import ViewPortfolioModal from "../components/ViewPortfolioModal";
import ConfirmVerifyPaymentModal from "../components/ConfirmVerifyPaymentModal";

interface IUnverifiedPaymentsPageProps {
	token?: string;
	dispatch?: any;
}

const UnverifiedPaymentsPage: React.FC<IUnverifiedPaymentsPageProps> = (props: IUnverifiedPaymentsPageProps) => {

	const {token} = props;
	const [selectedMarket, setSelectedMarket] = useState<MarketIdentifier>(MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver);
	const [unverifiedList, setUnverifiedList] = useState<Array<IUnverifiedPaymentTableRow>>([]);
	const [portfolioToView, setPortfolioToView] = useState<TradingProfile>();
	const [paymentToVerify, setPaymentToVerify] = useState<UnverifiedPayout>();

	useEffect(() => {
		readUnverifiedPayments().then().catch();
	}, [selectedMarket]);

	/**
	 * onChange for the market selector drop down
	 *
	 * @param e
	 */
	function onSelectedMarketChange(e): void {
		setSelectedMarket(e.target.value);
	}

	function closeViewPortfolioModal(): void {
		setPortfolioToView(null);
	}

	function finishVerifyingPayment(): void {
		setPaymentToVerify(null);
		readUnverifiedPayments().then().catch();
	}

	function cancelVerifyPayment(): void {
		setPaymentToVerify(null);
	}

	async function readUnverifiedPayments(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetUnverifiedPayoutsGet({marketIdentifier: selectedMarket});

			const data: Array<IUnverifiedPaymentTableRow> = cloneDeep(res).map((u: UnverifiedPayout): IUnverifiedPaymentTableRow => {
				return {
					raw: u,
					...u,
					onViewPortfolio: (u) => setPortfolioToView(u.tradingProfile),
					onVerifyPayment: (u) => setPaymentToVerify(u),
				};
			});

			setUnverifiedList(data);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<ViewPortfolioModal
				tp={portfolioToView}
				market={selectedMarket}
				onClose={closeViewPortfolioModal}
			/>

			<ConfirmVerifyPaymentModal
				payout={paymentToVerify}
				onFinished={finishVerifyingPayment}
				onCancel={cancelVerifyPayment}
			/>

			<Container className="my-5">
				<div className="mb-4">
					<h2>Unverified Payments</h2>
					<p>
						On this page you can choose a specific market from the drop-down list below to see the payouts
						that still need to be verified.
					</p>
					<div className="d-flex justify-content-center">
						<div className="w-100" style={{maxWidth: 500}}>

							<Label for="market">Select Market</Label>
							<Input type="select" id="market" name="market" placeholder="Select Market"
							       onChange={onSelectedMarketChange} value={selectedMarket}>
								<option value={-1} disabled>Select Market</option>
								<hr/>
								<MarketIdentifierDropDownValues/>
							</Input>
						</div>
					</div>
				</div>

			</Container>

			<div className="my-5 px-1 px-lg-2">
				<UnverifiedPaymentsTable data={unverifiedList}/>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IUnverifiedPaymentsPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(UnverifiedPaymentsPage);
