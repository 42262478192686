import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Token, Admin, AdminApi} from "client";
import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Spinner} from "reactstrap";
import {addError} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";

interface IProps {
	token: string;
	dispatch: any;
}

const Dashboard: React.FC<IProps> = ({token, dispatch}) => {

	const [admin, setAdmin] = useState<Admin>();

	useEffect(() => {
		getDashboardData().then().catch();
	}, [token]);

	async function getDashboardData() {
		try {
			const res: Admin = await new AdminApi(getConfig(token)).adminAdminInfoGet();
			setAdmin(res);
		} catch (err) {
			dispatch(addError(err))
		}
	}

	function createInputDisplay(label: string, value: string = "Not Available") {
		return (
			<Col md={6} xs={12}>
				<Label className="mt-1">{label}</Label>
				<Input value={value} contentEditable={false} disabled={true}/>
			</Col>
		)
	}

	return (
		<Container className="my-4">
			<Card>
				<CardHeader>Dashboard</CardHeader>
				<CardBody>
					{
						admin ?
							<Row>
								{createInputDisplay("Role:", admin.adminRole)}
								{createInputDisplay("Username:", admin.userName)}
								{createInputDisplay("First Name:", admin.firstName)}
								{createInputDisplay("Last Name:", admin.lastName)}
								{createInputDisplay("Email:", admin.email)}
							</Row> :
							<div className="d-flex justify-content-center">
								<Spinner/>
							</div>
					}
				</CardBody>
			</Card>
		</Container>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(Dashboard)
