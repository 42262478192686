import React, {ChangeEventHandler, ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {AdminApi, Article, ArticleMarket, Asset, CreateAdminBody, RealestateSecuritiesApi} from "client";
import {Button, Col, Input, Label, Modal, ModalFooter, ModalHeader, Row} from "reactstrap";
import SelectOptions, {ISelectOptions} from "./SelectOptions";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import find from "lodash.find";
import findIndex from "lodash.findindex";
import cloneDeep from "lodash.clonedeep";
import AssetInput from "./asset_manager/AssetInput";

interface IArticleFormModalProps {
	token?: string;
	dispatch?: any;
	open: boolean;
	editingArticle: Article;
	close(): void;
	onDone(): Promise<void>;
}

const defaultArticleForm: Article = {
	articleURL: "",
	thumbnail: null,
	title: "",
	creationTime: null,
	hideFromNewsFeed: false,
	articleMarkets: [],
};

const ArticleFormModal: React.FC<IArticleFormModalProps> = (props: IArticleFormModalProps) => {

	const {token, dispatch, open, editingArticle} = props;
	const [form, setForm] = useState<Article>(editingArticle ? editingArticle : defaultArticleForm);
	const [assets, setAssets] = useState<Array<Asset>>([]);
	const [markets, setMarkets] = useState<Array<ArticleMarket>>([]);

	useEffect(() => {
		getInitialData().then().catch();
	}, []);

	useEffect(() => {
		if (props.editingArticle) {
			setForm(props.editingArticle);
		} else {
			setForm(defaultArticleForm)
		}
	}, [JSON.stringify(props.editingArticle)]);

	/**
	 * Reset form & close modal
	 *
	 */
	function closeHelper(): void {
		setForm(defaultArticleForm);
		props.close();
	}

	/**
	 * Call DB to get list of assets & list of real estate article markets for use in the form
	 *
	 */
	async function getInitialData(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const assetRes = await new AdminApi(getConfig(token)).adminAssetListGet();
			setAssets(assetRes);
		} catch(e) {
			props.dispatch(addError(await e.json()));
		}

		try {
			const marketListRes = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesGetArticleMarketListGet({});
			setMarkets(marketListRes);
		} catch(e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Submit the new article, and call props.onDone() once complete
	 *
	 */
	async function saveArticle(): Promise<void> {
		props.dispatch(incrementLoading());

		if (props.editingArticle) {
			try {
				const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesArticlePut({article: form});
				props.dispatch(decrementLoading());
				await props.onDone();
			} catch(e) {
				props.dispatch(decrementLoading());
				props.dispatch(addError(await e.json()));
			}
		} else {
			try {
				const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesArticlePost({article: form});
				props.dispatch(decrementLoading());
				await props.onDone();
			} catch(e) {
				props.dispatch(decrementLoading());
				props.dispatch(addError(await e.json()));
			}
		}
	}

	/**
	 * onChange for normal form fields
	 *
	 * @param key
	 */
	function createOnChange(key: keyof Article): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Custom onChange for drop down
	 *
	 * @param e
	 */
	function changeAssetHelper(e): any {
		setForm({
			...form,
			thumbnail: e,
		})
	}

	/**
	 * Custom on change for visible on News Feed checkbox
	 * (Made ui reflect opposite of value for clearer use)
	 *
	 */
	function visibleOnNewsFeedToggle(): void {
		setForm({...form, hideFromNewsFeed: !form.hideFromNewsFeed});
	}

	const assetSelectOptions: Array<ISelectOptions> = assets.map((a: Asset) => {
		return {
			value: a._id,
			text: a.name,
		}
	});

	/**
	 * Handle toggling of the dynamic list of article market checkboxes.
	 * If index of the toggled checkbox cannot be found in the array "form.articleMarkets",
	 * this means we should push it into the array. If the index is found to be > -1, then
	 * we must splice
	 *
	 * @param _articleMarket
	 */
	function articleMarketCheckBoxOnChange(_articleMarket: ArticleMarket): any {
		const foundArticleIndex: number = findIndex(form.articleMarkets, {_id: _articleMarket._id});

		let articleMarkets;
		if (foundArticleIndex < 0) {
			articleMarkets = cloneDeep(form.articleMarkets).concat(_articleMarket);
		} else {
			articleMarkets = cloneDeep(form.articleMarkets);
			articleMarkets.splice(foundArticleIndex, 1);
		}

		setForm({
			...form,
			articleMarkets,
		})
	}

	function mapArticleMarketCheckBoxes(ams: Array<ArticleMarket> = []): ReactNode {
		return ams.map((am: ArticleMarket, i: number) => {

			function onCheckChangeHelper(): void {
				articleMarketCheckBoxOnChange(am);
			}

			const checked: number = findIndex(form.articleMarkets, {_id: am._id});

			return (
				<Row className="mb-2">
					<Label xs={8}>{am.title}</Label>
					<Col xs={4} className="d-flex justify-content-end align-items-center">
						<p>
							<input
								type="checkbox"
								name={`articleMarketCheck-${am._id}`}
								id={`articleMarketCheck-${am._id}`}
								checked={checked > -1}
								onChange={onCheckChangeHelper}
							/>
							<label htmlFor={`articleMarketCheck-${am._id}`}/>
						</p>
					</Col>
				</Row>
			)
		});
	}

	return (
		<Modal
			isOpen={open}
			fade={true}
			centered={true}
			contentClassName="px-3"
			toggle={closeHelper}
		>
			<ModalHeader toggle={closeHelper}>Add New Article</ModalHeader>

			<div className="my-3">
				{form.thumbnail && (
					<div className="d-flex justify-content-center mb-3">
						<img
							src={form.thumbnail.url}
							style={{
								maxWidth: "100%",
								maxHeight: 300,
								borderRadius: 5,
							}}
						/>
					</div>
				)}

				<div className="mb-3">
					<Label>Thumbnail*</Label>
					<AssetInput value={form.thumbnail} onChange={changeAssetHelper}/>
				</div>

				<Label>Article URL*</Label>
				<Input value={form.articleURL} placeholder="Article URL" onChange={createOnChange("articleURL")} className="mb-3"/>

				<Label>Title*</Label>
				<Input value={form.title} placeholder="Article Title" onChange={createOnChange("title")} className="mb-3"/>

				<Label>Source*</Label>
				<Input value={form.source} placeholder="Article source" onChange={createOnChange("source")} className="mb-3"/>

				<Label>Description*</Label>
				<Input value={form.description} type="textarea" placeholder="Article Description" onChange={createOnChange("description")} className="mb-3"/>

				<Row className="mb-3">
					<Label xs={8}>This article is visible in the news feed</Label>
					<Col xs={4} className="d-flex justify-content-end align-items-center">
						<p>
							<input
								type="checkbox"
								name="visibleInNewsFeedCheckBox"
								id="visibleInNewsFeedCheckBox"
								checked={!form.hideFromNewsFeed}
								onChange={visibleOnNewsFeedToggle}
							/>
							<label htmlFor="visibleInNewsFeedCheckBox"/>
						</p>
					</Col>
				</Row>

				<div>
					<p className="text-secondary font-italic mb-0">
						This article will appear on the following article markets:
					</p>
					{mapArticleMarketCheckBoxes(markets)}
				</div>
			</div>

			<ModalFooter>
				<Button color="primary" onClick={saveArticle}>
					Save Article
				</Button>
			</ModalFooter>
		</Modal>
	)
};

export default connect((store: IStore, props: IArticleFormModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ArticleFormModal);
