import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {MarketApi, MarketIdentifier, UnverifiedPayout} from "client";
import cloneDeep from "lodash.clonedeep";
import GenericSuccessModal from "./GenericSuccessModal";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";
import {decimalAsPercent, formatCurrency, numberWithCommas} from "../utils/formatters";

interface IConfirmVerifyPaymentModalProps {
	token?: string;
	dispatch?: any;
	payout: UnverifiedPayout;

	onFinished(): void;

	onCancel(): void;
}

const ConfirmVerifyPaymentModal: React.FC<IConfirmVerifyPaymentModalProps> = (props: IConfirmVerifyPaymentModalProps) => {

	const {token, payout} = props;
	const [dataClone, setDataClone] = useState<UnverifiedPayout>();
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (payout && Object.keys(payout).length > 0) {
			setDataClone(cloneDeep(payout))
		}
	}, [JSON.stringify(payout)]);

	function onSuccess(): void {
		setShowSuccess(false);
		props.onFinished();
	}

	async function verify(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new MarketApi(getConfig(token)).marketAdminApproveVerifiedPayoutsPost({
				adminApproveVerifiedPayoutsBody: {
					tradingProfile: payout.tradingProfile,
					securityID: payout.expiredSecurity._id,
				},
			});

			setShowSuccess(true);
		}catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onSuccess}
				body="Payout verified successfully"
			/>

			<Modal
				isOpen={payout && Object.keys(payout).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={props.onCancel}
			>
				{dataClone && (
					<React.Fragment>
						<ModalHeader toggle={props.onCancel}>Verify Payment</ModalHeader>

						<ModalBody>
							<div className="mb-3">
								<Label for="market" className="text-secondary">Market</Label>
								<p>
									{getMarketDisplayName(dataClone.market.marketIdentifier)}
								</p>
							</div>

							<div className="mb-3">
								<Label for="userEmail" className="text-secondary">User</Label>
								<p>
									{dataClone.tradingProfile.parentUser["email"]}
								</p>
							</div>

							<div className="mb-3">
								<Label for="securityName" className="text-secondary">Security</Label>
								<p>
									{dataClone.expiredSecurity.name}
								</p>
							</div>

							<div className="mb-3">
								<Label for="amountOfSecurityOwned" className="text-secondary">Amount of security owned</Label>
								<p>
									{numberWithCommas(dataClone.amountOfSecurityOwned)}
								</p>
							</div>

							<div className="mb-3">
								<Label for="marketSharePercent" className="text-secondary">Market Share</Label>
								<p>
									{decimalAsPercent(dataClone.marketShare)}
								</p>
							</div>

							<div className="mb-3">
								<Label for="amountToBePaidOut" className="text-secondary">Amount to be paid</Label>
								<p>
									{formatCurrency(dataClone.amountToBePaidOut)}
								</p>
							</div>
						</ModalBody>

						<ModalFooter>
							<Button color="link" onClick={props.onCancel}>
								Cancel
							</Button>

							<Button color="success" onClick={verify}>
								Verify Payment
							</Button>
						</ModalFooter>
					</React.Fragment>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IConfirmVerifyPaymentModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ConfirmVerifyPaymentModal);
