import React from "react";

export interface IColumn {
	dataField: string;
	text: string;
	formatter?: any;
	sort?: boolean;
	classes?: string;
	headerClasses?: string;
	headerAlign?: "left" | "center" | "right";
}

/**
 * adds className to cell to grant white-space: pre; css for line break
 *
 * @param cell
 */
export function whiteSpaceCellFormatter(cell: string): JSX.Element {
	return (
		<p className="white-space-pre mb-0">
			{cell}
		</p>
	)
}
