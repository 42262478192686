import React from "react";
import {MarketIdentifier} from "client";

/**
 * function used both in the drop-down component, but also anywhere else
 * the UI needs to display the "user friendly" version
 *
 * @param market
 */
export function getMarketDisplayName(market: MarketIdentifier): string {
	switch(market) {
		case MarketIdentifier.TestMarket:
			return "Test Market";
		case MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver:
			return "Real Estate Securities Play Money Vancouver";
		default:
			return "Market";
	}
}

const MarketIdentifierDropDownValues: React.FC = () => {

	return (
		<React.Fragment>
			<option value={MarketIdentifier.TestMarket}>{getMarketDisplayName(MarketIdentifier.TestMarket)}</option>
			<option value={MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver}>{getMarketDisplayName(MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver)}</option>
		</React.Fragment>
	)
};

export default MarketIdentifierDropDownValues;
