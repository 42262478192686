import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {AdminApi, MarketApi, MarketIdentifier, Order, Security, TradingProfile} from "client";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import cloneDeep from "lodash.clonedeep";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";

interface IDeleteSecurityModalProps {
	token?: string;
	dispatch?: any;
	security: Security;
	market: MarketIdentifier;

	onFinished(): void;
	onCancel(): void;
}

const DeleteSecurityModal: React.FC<IDeleteSecurityModalProps> = (props: IDeleteSecurityModalProps) => {

	const {token, security, market} = props;
	const [dataClone, setDataClone] = useState<Security>();

	useEffect(() => {
		if (security && Object.keys(security).length > 0) {
			setDataClone(cloneDeep(security))
		}
	}, [JSON.stringify(security)]);

	function closeHelper(): void {
		props.onCancel();
	}

	/**
	 * call api to confirm deletion of the selected security
	 *
	 */
	async function confirmDeleteSelectedSecurity(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketRemoveSecurityDelete({security});

			props.onFinished();
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<Modal
			isOpen={security && Object.keys(security).length > 0}
			fade={true}
			centered={true}
			contentClassName="px-3"
			toggle={closeHelper}
		>
			<ModalHeader toggle={closeHelper}>Delete Security</ModalHeader>

			{dataClone && (
				<React.Fragment>
					<ModalBody>
						<p>
							Are you absolutely sure you want to delete the "{dataClone.name}" security from the "{getMarketDisplayName(market)}" market?
						</p>
					</ModalBody>

					<ModalFooter>
						<Button color="link" onClick={closeHelper} className="mr-3">
							Cancel
						</Button>

						<Button color="danger" onClick={confirmDeleteSelectedSecurity}>
							Confirm Deletion
						</Button>
					</ModalFooter>
				</React.Fragment>
			)}
		</Modal>
	);
};

export default connect((store: IStore, props: IDeleteSecurityModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(DeleteSecurityModal)
