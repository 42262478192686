import React, {ChangeEventHandler} from "react";
import {Question} from "client";
import {Card, CardBody, CardHeader, Input, Label} from "reactstrap";
import ReorderButtons from "./ReorderButtons";
import {FiArrowDown, FiX} from "react-icons/all";

interface IAdminFAQItemProps {
	title: string;
	description: string;
	i: number;
	showDownArrow: boolean;

	onChange(key: keyof Question, value: string): void;
	onMove(up: boolean): void;
	onDelete(): void;
}

const AdminFAQItem: React.FC<IAdminFAQItemProps> = (props: IAdminFAQItemProps) => {

	const {title, description, i, showDownArrow} = props;

	function onChangeHelper(key: keyof Question): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			props.onChange(key, e.target.value)
		}
	}

	function moveHelper(up: boolean): () => void {
		return () => {
			props.onMove(up === true);
		}
	}

	function deleteHelper(e): void {
		e.preventDefault();
		props.onDelete();
	}

	return (
		<Card>
			<CardHeader className="d-flex justify-content-between">
				<h6 className="mb-0 d-inline">
					{`FAQ item ${i + 1}`}
				</h6>

				<div className="d-flex">
					<ReorderButtons
						upCallback={moveHelper(true)}
						downCallback={moveHelper(false)}
						showUp={i > 0}
						showDown={showDownArrow}
					/>

					<a href="#" onClick={deleteHelper} className="ml-4 text-danger">
						<FiX
							size="1.5rem" style={{maxHeight: 40}}
						/>
					</a>
				</div>
			</CardHeader>
			<CardBody>

				<Label>Question</Label>
				<Input value={title} placeholder="Question Title" onChange={onChangeHelper("title")} className="mb-3"/>

				<Label>Answer</Label>
				<Input type="textarea" style={{minHeight: 140}} value={description} placeholder="Question Answer" onChange={onChangeHelper("description")} className="mb-3"/>
			</CardBody>

		</Card>
	);
};

export default AdminFAQItem;
