import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FiX} from "react-icons/all";
import {ArticleMarket, RealestateSecuritiesApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";

interface IArticleMarketPreviewProps {
	token?: string;
	dispatch?: any;
	articleMarket: ArticleMarket;
	onDoneDelete(): Promise<void>;
	onSelectForEditing(): void;
}

const ArticleMarketPreview: React.FC<IArticleMarketPreviewProps> = (props: IArticleMarketPreviewProps) => {

	const {token, articleMarket} = props;
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	function toggleShowDeleteModal(): void {
		setShowDeleteModal(!showDeleteModal);
	}

	/**
	 * Call backend and delete this article market, on success call props.onDoneDelete();
	 *
	 */
	async function deleteArticleMarket(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesArticleMarketDelete({articleMarket});

			toggleShowDeleteModal();
			await props.onDoneDelete();
			props.dispatch(decrementLoading())
		} catch(e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(await e.json()));
		}

	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showDeleteModal}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={toggleShowDeleteModal}
			>
				<ModalHeader toggle={toggleShowDeleteModal}>{`Delete ${articleMarket.title}?`}</ModalHeader>

				<ModalBody>
					<p>{`Are you sure you want to delete ${articleMarket.title}?`}</p>
				</ModalBody>

				<ModalFooter className="d-flex justify-content-between">
					<Button color="link" onClick={toggleShowDeleteModal}>
						Cancel
					</Button>

					<Button color="danger" onClick={deleteArticleMarket}>
						Yes, Delete
					</Button>
				</ModalFooter>
			</Modal>

			<Card className="w-100">
				<CardBody>
					<div className="d-flex justify-content-end mb-2">
						<a className="text-danger" href="#" onClick={toggleShowDeleteModal}>
							<FiX size="1.75rem" style={{maxHeight: 50}}/>
						</a>
					</div>

					<div className="d-flex justify-content-center mb-3">
						<img
							src={(articleMarket && articleMarket.thumbnail) ? articleMarket.thumbnail.url : ""}
							style={{
								maxWidth: "100%",
								height: 200,
								borderRadius: 5,
								objectFit: "contain",
							}}
						/>
					</div>

					<div className="mb-3">
						<h3 className="text-center">
							{articleMarket ? articleMarket.title : ""}
						</h3>
					</div>

					<div className="d-flex justify-content-center">
						<Button color="info" onClick={props.onSelectForEditing}>
							Edit Article Market
						</Button>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IArticleMarketPreviewProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ArticleMarketPreview);
