import React from "react";
import {Container} from "reactstrap";

const NotFound: React.FC = () => {
	return (
		<Container>
			<img src={process.env.PUBLIC_URL + "/images/404.jpg"} alt="404 not found"/>
		</Container>
	);
}

export default NotFound;