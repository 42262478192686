import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Button, Container} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {FaqApi, Question} from "client";
import cloneDeep from "lodash.clonedeep";
import AdminFAQItem from "../components/AdminFAQItem";
import {getConfig} from "../services/clientApis";
import GenericSuccessModal from "../components/GenericSuccessModal";

interface IRealEstateFAQPageProps {
	token?: string;
	dispatch?: any;
}

const RealEstateFAQPage: React.FC<IRealEstateFAQPageProps> = (props: IRealEstateFAQPageProps) => {

	const {token} = props;
	const [faq, setFaq] = useState<Array<Question>>([]);
	const [keyTracker, setKeyTracker] = useState(1);
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		readCurrentFAQ().then().catch();
	}, []);

	async function readCurrentFAQ(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FaqApi().faqListGet();

			setFaq(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	async function updateFAQ(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new FaqApi(getConfig(token)).updateFaqPut({question: faq});
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
		setShowSuccess(true);
	}

	function closeSuccessModal(): void {
		setShowSuccess(false);
		readCurrentFAQ().then().catch();
	}

	function addNew(): void {
		const list: Array<Question> = cloneDeep(faq);
		list.push({title: "", description: ""});
		setFaq(list);
	}

	function handleFieldChange(key: keyof Question, value: string, i: number): void {
		const questions: Array<Question> = cloneDeep(faq);
		questions[i][key] = value;
		setFaq(questions);
	}

	function moveQuestion(up: boolean, i: number): void {
		if (
			(up && i === 0) ||
			(!up && i >= (faq.length - 1))
		) {
			return
		}

		const questions: Array<Question> = cloneDeep(faq);

		const a: Question = cloneDeep(faq[i]);
		const swapIndex: number = up ? (i - 1) : (i + 1);
		const b: Question = cloneDeep(faq[swapIndex]);

		questions[i] = b;
		questions[swapIndex] = a;
		setFaq(questions);
		setKeyTracker(keyTracker + 1);

	}

	function deleteQuestion(i: number): void {
		const questions: Array<Question> = cloneDeep(faq);
		questions.splice(i, 1);
		setFaq(questions);
	}

	function createFAQ(_faq: Array<Question> = []): ReactNode {
		if (_faq.length < 1) {
			return (
				<div>
					<p className="text-center font-italic text-muted">
						No questions added yet.
					</p>
				</div>
			)
		}

		return _faq.map((q: Question, i: number) => {

			function onChangeHelper(key: keyof Question, value: string): any {
				handleFieldChange(key, value, i);
			}

			function moveHelper(up: boolean): void {
				moveQuestion(up, i);
			}

			function deleteHelper(): void {
				deleteQuestion(i);
			}

			return (
				<div className="mb-4">
					<AdminFAQItem
						title={q.title}
						description={q.description}
						i={i}
						showDownArrow={(i + 1) < _faq.length}
						onChange={onChangeHelper}
						onMove={moveHelper}
						onDelete={deleteHelper}
					/>
				</div>
			);
		});
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={closeSuccessModal}
				body="FAQ updated successfully."
			/>

			<Container className="my-5">
				<div className="mb-4">
					<h2>Real Estate Portal Frequently Asked Questions</h2>
					<p>
						Here you can update the FAQ for the Real Estate portal.
						Add, edit, re-order, and delete as desired, and click the "Save FAQ" button at the bottom of the
						page to update your changes.
					</p>
				</div>

				<div>
					<div key={keyTracker}>
						{createFAQ(faq)}
					</div>

					<div className="d-flex justify-content-center mt-4">
						<Button color="primary" onClick={addNew}>
							Add Question
						</Button>
					</div>
				</div>

				<hr/>

				<div className="d-flex justify-content-end">
					<Button color="success" onClick={updateFAQ}>
						Save FAQ
					</Button>
				</div>
			</Container>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IRealEstateFAQPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(RealEstateFAQPage);
