import React, {ReactNode} from "react";
import {FiArrowDown, FiArrowUp} from "react-icons/all";

interface IReorderButtonsProps {
	children?: ReactNode;
	showUp?: boolean;
	showDown?: boolean;
	upCallback(): void;
	downCallback(): void;
}

const ReorderButtons: React.FC<IReorderButtonsProps> = (props: IReorderButtonsProps) => {

	const {children, showUp, showDown} = props;

	function upHelper(e): void {
		e.preventDefault();
		props.upCallback();
	}

	function downHelper(e): void {
		e.preventDefault();
		props.downCallback();
	}

	return (
		<div className="d-flex">
			<span>
				{children}
			</span>

			<div className="d-flex">
				{showUp && (
					<a href="#" onClick={upHelper} className="mx-2">
						<FiArrowUp size="1.5rem" style={{maxHeight: 40}}/>
					</a>
				)}

				{showDown && (
					<a href="#" onClick={downHelper}>
						<FiArrowDown size="1.5rem" style={{maxHeight: 40}}/>
					</a>
				)}
			</div>
		</div>
	);
};

ReorderButtons.defaultProps = {
	showUp: true,
	showDown: true,
};

export default ReorderButtons;
