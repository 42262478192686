import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {AddSecurityBody, AdminApi, MarketApi, MarketIdentifier} from "client";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import NumberFormat from "react-number-format";
import MarketIdentifierDropDownValues, {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";
import moment from "moment";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import GenericSuccessModal from "./GenericSuccessModal";

interface IAddSecurityModalProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	market: MarketIdentifier;

	onClose(getNewData: boolean): void;
}

const defaultAddSecurityForm: AddSecurityBody = {
	marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
	expirationDate: new Date() as any,
	expectedValue: "",
	name: "",
	description: "",
};

const AddSecurityModal: React.FC<IAddSecurityModalProps> = (props: IAddSecurityModalProps) => {

	const {token, isOpen, market} = props;
	const [form, setForm] = useState<AddSecurityBody>({...defaultAddSecurityForm, marketIdentifier: market});
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		setForm({...defaultAddSecurityForm, marketIdentifier: market});
	}, [isOpen, market]);

	function closeHelper(): void {
		setForm(defaultAddSecurityForm);
		props.onClose(false);
	}

	function onSuccessfulSubmission(): void {
		setForm({...defaultAddSecurityForm, marketIdentifier: market});
		setShowSuccess(false);
		props.onClose(true);
	}

	function createOnChange(key: keyof AddSecurityBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	function onNumberChange(e): void {
		let v = e.floatValue;
		if (v > 100000000) {
			v = 100000000;
		}

		setForm({
			...form,
			expectedValue: v,
		});
	}

	async function submitNewSecurity(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = new MarketApi(getConfig(token)).marketAddSecurityPost({
				addSecurityBody: {
					marketIdentifier: form.marketIdentifier,
					expirationDate: moment(form.expirationDate, "YYYY-MM-DD").toDate().getTime(),
					expectedValue: form.expectedValue,
					name: form.name,
					description: form.name,
				},
			});

			setShowSuccess(true);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onSuccessfulSubmission}
				body="Security added successfully"
			/>

			<Modal
				isOpen={isOpen}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={closeHelper}
			>
				<ModalHeader toggle={closeHelper}>Add New Security to the "{getMarketDisplayName(form.marketIdentifier)}" market</ModalHeader>

				<ModalBody>
					<p>
						Set the details of the new security:
					</p>

					<div className="mb-3">
						<Label for="market">Select Market</Label>
						<Input type="select" id="market" name="market" placeholder="Select Market"
						       onChange={createOnChange("marketIdentifier")} value={form.marketIdentifier}>
							<option value={-1} disabled>Select Market</option>
							<hr/>
							<MarketIdentifierDropDownValues/>
						</Input>
					</div>

					<div className="mb-3">
						<Label>Expiration Date</Label>
						<Input type="date" value={form.expirationDate} placeholder="Expiration Date"
						       onChange={createOnChange("expirationDate")}/>
					</div>

					<div className="mb-3">
						<Label>Expected Value</Label>
						<NumberFormat
							placeholder="Expected Value"
							prefix="$"
							allowLeadingZeros={false}
							value={form.expectedValue as any}
							customInput={Input}
							thousandSeparator={true}
							decimalScale={2}
							onValueChange={onNumberChange}
							allowNegative={false}
						/>
					</div>

					<div className="mb-3">
						<Label>Name</Label>
						<Input value={form.name} placeholder="Name"
						       onChange={createOnChange("name")}/>
					</div>

					{/*<div className="mb-3">*/}
					{/*	<Label>Description</Label>*/}
					{/*	<Input value={form.description} placeholder="Description"*/}
					{/*	       onChange={createOnChange("description")}/>*/}
					{/*</div>*/}

				</ModalBody>

				<ModalFooter>
					<Button color="primary" onClick={submitNewSecurity}>
						Add Security
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IAddSecurityModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(AddSecurityModal)
