import React, {ReactNode} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {TradingProfile, User} from "client";
import {Button} from "reactstrap";

export interface ITradingProfilesTableRow extends TradingProfile {
	raw: TradingProfile,
	onGiveMoney(_tp: TradingProfile): void;
	onGiveSecurities(_tp: TradingProfile): void;
	onViewPortfolio(_tp: TradingProfile): void;
	onTakeMoney(_tp: TradingProfile): void;
	onTakeSecurities(_tp: TradingProfile): void;
}

interface ITradingProfilesTableProps {
	data: Array<ITradingProfilesTableRow>;
}

const TradingProfilesTable: React.FC<ITradingProfilesTableProps> = (props: ITradingProfilesTableProps) => {

	const {data} = props;

	function createNoDataMessage(): ReactNode {
		return (
			<div className="my-2">
				<p className="text-center">
					No Trading Profiles
				</p>
			</div>
		);
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			columns={[
				{
					dataField: "parentUser[email]",
					text: "Email",
					sort: true,
				},
				{
					dataField: "giftButtons",
					text: "Gift Money / Securities",
					formatter: makeManageButtons,
				}
			]}
			keyField={"key"}
			data={
				data.filter((d) => {
					for (let k in d) {
						if (!d.hasOwnProperty(k)) {continue}
						if ((typeof d[k] === "string" || typeof d[k] === "undefined") && (!d[k] || d[k].length < 1)) {
							d[k] = "-"
						}
					}
					return d;
				}).map((d: TradingProfile, i: number) => {
					return {...d, key: i}
				})
			}
			noDataIndication={createNoDataMessage}
			defaultSorted={[{dataField: "parentUser[email]", order: "asc"}]}
		/>
	);
};

function makeManageButtons(cell: string, row: ITradingProfilesTableRow): ReactNode {

	function onGiftMoney(): void {
		row.onGiveMoney(row.raw);
	}

	function onGiftSecurities(): void {
		row.onGiveSecurities(row.raw);
	}

	function onViewPortfolio(): void {
		row.onViewPortfolio(row.raw);
	}

	function onTakeMoney(): void {
		row.onTakeMoney(row.raw);
	}

	function onTakeSecurities(): void {
		row.onTakeSecurities(row.raw);
	}

	return (
		<div className="d-flex">
			<Button onClick={onGiftMoney} color="success" className="mr-3">
				Gift Money
			</Button>

			<Button onClick={onGiftSecurities} color="success" className="mr-3">
				Gift Securities
			</Button>

			<Button onClick={onViewPortfolio} color="primary" className="mr-3">
				View Portfolio
			</Button>

			<Button onClick={onTakeMoney} color="danger" className="mr-3">
				Take Money
			</Button>

			<Button onClick={onTakeSecurities} color="danger" className="mr-3">
				Take Securities
			</Button>
		</div>
	);
}

export default TradingProfilesTable;
