import {MetaType} from "./MetaReducer";
import {IAction} from "../index";
import {APIError} from "client";

export function incrementLoading(): IAction<MetaType.LOADING, number> {
	return {
		type: MetaType.LOADING,
		payload: 1,
	}
}

export function decrementLoading(): IAction<MetaType.LOADING, number> {
	return {
		type: MetaType.LOADING,
		payload: -1,
	}
}

export function login(token: string): IAction<MetaType.LOGIN, string> {
	return {
		type: MetaType.LOGIN,
		payload: token,
	}
}

export function logout(): IAction<MetaType.LOGOUT, any> {
	return {
		type: MetaType.LOGOUT,
	}
}

const defaultError: () => APIError = () => ({messages: ["An unexpected error has occurred. Please check your request and try again."]});
export async function addError(err?: {json: () => Promise<APIError>} | APIError): Promise<IAction<MetaType.ADD_ERROR, APIError>> {

	let _err: APIError;

	try {
		if (err === undefined || err === null) {
			_err = defaultError();
		} else if (err.hasOwnProperty("messages") && Array.isArray((err as APIError).messages) && (err as APIError).messages.length > 0) {
			_err = err as APIError;
		} else {
			try {
				_err = await (err as { json: () => Promise<APIError> }).json();
			} catch {
				_err = defaultError();
			}
		}
	} catch {
		_err = defaultError();
	}

	return {
		type: MetaType.ADD_ERROR,
		payload: _err,
	}
}

export function removeErr(index: number = 0): IAction<MetaType.REMOVE_ERR, number> {
	return {
		type: MetaType.REMOVE_ERR,
		payload: index,
	}
}

export function toggleSidebar(payload: boolean): IAction<MetaType.SIDEBAR_TOGGLE, boolean> {
	return {
		type: MetaType.SIDEBAR_TOGGLE,
		payload,
	}
}
