import React, {ChangeEventHandler, useState} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row} from "reactstrap";
import {AdminApi, APIError} from "client";
import {useHistory} from "react-router-dom";
import {addError, decrementLoading, incrementLoading, login} from "../services/redux/meta/MetaActions";
import {connect} from "react-redux";

interface ILoginFields {
	userName?: string;
	password?: string;
}

interface ILoginProps {
	dispatch: any;
}

const Login: React.FC<ILoginProps> = (props: ILoginProps) => {

	const [form, setForm] = useState<ILoginFields>();
	const history = useHistory();

	function createOnChange(key: keyof ILoginFields): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	async function attemptLogin(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new AdminApi().adminFormsLoginPost({
				adminLoginBody: {
					username: form.userName,
					password: form.password,
				}
			});
			props.dispatch(login(res.token));
			history.push("/dashboard");
		} catch (err) {
			props.dispatch(addError(err));
		}
		props.dispatch(decrementLoading());
	}

	return (
		<>
			<img
				className="position-absolute  h-100 w-100"
				style={{
					zIndex: -1,
					objectFit: "cover",
				}}
				src={process.env.PUBLIC_URL + "/images/login_background-min.jpg"} alt="login screen background"
			/>
			<Container className="justify-content-sm-center align-items-center d-flex vh-100 flex-column">
				<h2 className="mb-4 text-center px-2" style={{maxWidth: 400}}>{process.env.REACT_APP_COMPANY_NAME.toUpperCase()}</h2>
				<Card className="mb-5" style={{width: 300, zIndex: 1}}>
					<CardHeader>
						Admin Login
					</CardHeader>
					<CardBody>
						<div className="mb-4">
							<Label>Username</Label>
							<Input type="text" id="username" name="username"
								   onChange={createOnChange("userName")}/>
						</div>

						<div className="mb-4">
							<Label>Password</Label>
							<Input type="password" id="password" name="password"
								   onChange={createOnChange("password")}/>
						</div>

						<div className="d-flex justify-content-end">
							<Button color="primary" onClick={attemptLogin}>
								Log in
							</Button>
						</div>
					</CardBody>
				</Card>
				<div className="mb-3 position-absolute" style={{bottom: 0, zIndex: 0}}>
					{/*<p className="small w-100 text-center d-none d-sm-block">*/}
					{/*	Created and maintained by...*/}
					{/*</p>*/}
					<a href="https://frameonesoftware.com/" target="_blank">
						<img
							style={{maxWidth: 175}}
							className="w-100"
							alt="Logo for Frame One Software Inc."
							src={process.env.PUBLIC_URL + "/images/frame_one_software_logo.png"}
						/>
					</a>
				</div>
			</Container>
		</>
	)
};

export default connect()(Login);
