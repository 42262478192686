import React, {ReactNode, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {Admin, MarketIdentifier, User} from "client";
import {Button} from "reactstrap";
import {connect} from "react-redux";
import UserChangePasswordModal from "./UserChangePasswordModal";
import DisableUserModal from "./DisableUserModal";
import EnableUserModal from "./EnableUserModal";
import {marketIdentifierParser} from "../utils/marketIdentifierParser";

interface IProps {
	data: Array<User>;
	onUpdate(): Promise<void>;
}

const UserTable: React.FC<IProps> = (props) => {

	const {data} = props;

	function createNoDataMessage(): ReactNode {
		return (
			<div className="my-2">
				<p className="text-center">
					No Users
				</p>
			</div>
		)
	}

	function makeManageButtons(cell: string, row: User): ReactNode {
		return <UserManageButtons user={row} onDone={props.onUpdate}/>
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			triped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			noDataIndication={createNoDataMessage}
			defaultSorted={[{dataField: "email", order: "asc"}]}
			data={data.filter((d) => {
				for (let k in d) {
					if (!d.hasOwnProperty(k)) {continue}
					if ((typeof d[k] === "string" || typeof d[k] === "undefined") && (!d[k] || d[k].length < 1)) {
						d[k] = "-"
					}
				}
				return d;
			}).map((d: User, i: number) => {
				return {...d, key: i}
			})}
			keyField={"_id"}
			columns={[
				{
					dataField: "_id",
					text: "id",
					sort: true,
				},
				{
					dataField: "email",
					text: "Email",
					sort: true,
				},
				{
					dataField: "marketsJoined",
					text: "Market(s)",
					formatter: formatMarketsJoined,
				},
				{
					dataField: "manageButtons",
					text: "Manage",
					formatter: makeManageButtons,
				}
			]}
		/>
	);
};

function formatMarketsJoined(markets: Array<MarketIdentifier>): JSX.Element {
	return (
		<ul>
			{markets.map((market: MarketIdentifier, i: number) => {
				return (
					<li>
						{marketIdentifierParser(market)}
					</li>
				)
			})}
		</ul>
	);
}

const _UserManageButtons: React.FC<{ user: User, dispatch?: any, onDone: () => Promise<void> }> = ({user, dispatch, onDone}) => {

	const [changePasswordOpen, setChangePasswordOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [userToDisable, setUserToDisable] = useState<User>();
	const [userToEnable, setUserToEnable] = useState<User>();

	function changePassword() {
		setChangePasswordOpen(true);
	}

	function deleteUser() {
		setDeleteOpen(true);
	}

	function closeChangePassword() {
		setChangePasswordOpen(false);
	}

	function closeDeleteUser() {
		setDeleteOpen(false);
	}

	function selectDisable(): void {
		setUserToDisable(user);
	}

	function selectEnable(): void {
		setUserToEnable(user);
	}

	function cancelActiveToggling(): void {
		setUserToDisable(null);
		setUserToEnable(null);
	}

	function doneActiveToggling(): void {
		cancelActiveToggling();
		onDone().then().catch();
	}

	return (
		<React.Fragment>
			<UserChangePasswordModal user={user} close={closeChangePassword} onDone={onDone} open={changePasswordOpen}/>
			<DisableUserModal user={userToDisable} onCancel={cancelActiveToggling} onFinished={doneActiveToggling}/>
			<EnableUserModal user={userToEnable} onCancel={cancelActiveToggling} onFinished={doneActiveToggling}/>

			<div className="d-flex">
				<Button onClick={changePassword} className="mr-3" color="primary">
					Edit Password
				</Button>

				{user.active ? (
					<Button color="danger" onClick={selectDisable}>
						Disable User
					</Button>
				) : (
					<Button color="success" onClick={selectEnable}>
						Enable User
					</Button>
				)}
			</div>
		</React.Fragment>
	);
};
const UserManageButtons = connect()(_UserManageButtons);

export default UserTable;
