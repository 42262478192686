import moment from "moment";
import {OrderType} from "client";

const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 2,
	useGrouping: true,
});

function formatCurrency(n: any): string {
	return (n !== undefined && n !== null && !isNaN(n)) ? (currencyFormatter.format(n)) : "-";
}

function numberWithCommas(x = 0) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function integerAsPercent(x: number | string): string {
	return x + "%"
}

function decimalAsPercent(x: number): string {
	return !isNaN(x) ? (x * 100).toFixed(2) + "%" : "-";
}

function expectedTrueToAboveBelow(expected: boolean): string {
	return expected ? "Above" : "Below";
}

function parseActiveInactive(active: boolean = false): string {
	return active ? "Active" : "Inactive"
}

function momentStringFromMilliseconds(ms: number): string {
	return moment(ms).format("MMM Do YYYY")
}

function parseOrderTypeToString(orderType: OrderType): string {
	switch (orderType) {
		case OrderType.BUYORDER:
			return "buy order";
		case OrderType.SELLORDER:
			return "sell order";
		default:
			return "order";
	}
}

/**
 * For use in the forms/modals where the user "sells to a buyer order" or
 * "buys from a sell order". This is because the form only lets the user type
 * in numbers, which get multiplied by 1,000 to determine actual quantity,
 * but the backend saves with quantity with the 1,000 multiplier applied,
 * so it must be parsed to be displayed in forms.
 *
 * @param x
 */
function invertQty(x: number): number {
	return x / parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER);
}

export {
	formatCurrency,
	numberWithCommas,
	integerAsPercent,
	decimalAsPercent,
	expectedTrueToAboveBelow,
	parseActiveInactive,
	momentStringFromMilliseconds,
	parseOrderTypeToString,
	invertQty,
};
