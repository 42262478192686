import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {Admin, AdminApi} from "client";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";

interface IChangePasswordModalProps {
	token?: string;
	dispatch?: any;
	admin: Admin;
	open: boolean
	close(): void;
	onDone(): Promise<void>;
}

interface IChangePasswordForm {
	p: string;
	confirmP: string;
}

const ChangePasswordModal: React.FC<IChangePasswordModalProps> = (props: IChangePasswordModalProps) => {

	const {token, admin, open} = props;
	const [form, setForm] = useState<IChangePasswordForm>({p: "", confirmP: ""});

	function createOnChange(key: keyof IChangePasswordForm): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Submit api for updating the selected admin's password.
	 * Successful submission should call props.onDone() to close modal.
	 *
	 */
	async function submitUpdatePassword(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new AdminApi(getConfig(token)).adminFormsChangeAdminPasswordPut({
				changeAdminPassword: {
					_id: admin._id,
					password: form.p,
					confirmPassword: form.confirmP,
				},
			});

			props.dispatch(decrementLoading());
			await props.onDone();

		} catch(e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}

	}

	return (
		<Modal
			isOpen={open}
			fade={true}
			centered={true}
			contentClassName="px-3"
			toggle={props.close}
		>
			<ModalHeader toggle={props.close}>{`Update password for user "${admin.userName}"`}</ModalHeader>

			<ModalBody className="my-3">
				<Label>New Password</Label>
				<Input type="password" value={form.p} onChange={createOnChange("p")} className="mb-3"/>

				<Label>Confirm New Password</Label>
				<Input type="password" value={form.confirmP} onChange={createOnChange("confirmP")} className="mb-3"/>
			</ModalBody>

			<ModalFooter>
				<Button color="primary" onClick={submitUpdatePassword}>
					Confirm
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IChangePasswordModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ChangePasswordModal);
