import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../services/redux/defaultStore";
import {MarketApi, MarketIdentifier, Security} from "client";
import {addError, decrementLoading, incrementLoading} from "../services/redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import GenericSuccessModal from "./GenericSuccessModal";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {getMarketDisplayName} from "./MarketIdentifierDropDownOptions";
import NumberFormat from "react-number-format";
import {formatCurrency} from "../utils/formatters";

interface IUpdateSecurityRealValueModalProps {
	token?: string;
	dispatch?: any;
	security: Security;
	market: MarketIdentifier;

	onFinished(): void;

	onCancel(): void;
}

const UpdateSecurityRealValueModal: React.FC<IUpdateSecurityRealValueModalProps> = (props: IUpdateSecurityRealValueModalProps) => {

	const {token, security, market} = props;
	const [realValue, setRealValue] = useState();
	const [dataClone, setDataClone] = useState<Security>();
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (security && Object.keys(security).length > 0) {
			setDataClone(security);
			setRealValue(security.realValueAtExpiration);
		}
	}, [JSON.stringify(security)]);

	function closeHelper(): void {
		setRealValue(0);
		props.onCancel();
	}

	function onSuccessfulSubmission(): void {
		setRealValue(0);
		setShowSuccess(false);
		props.onFinished();
	}

	function onRealValueChange(e): void {
		let v = e.floatValue;
		if (v > 100000000) {
			v = 100000000;
		}

		setRealValue(v);
	}

	async function updateSecurityRealValue(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new MarketApi(getConfig(token)).marketUpdateExpirationValuePut({
				updateExpirationValueRequest: {
					security,
					realValueAtExpiration: realValue,
				},
			});

			setShowSuccess(true);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={onSuccessfulSubmission}
				body="Real Value updated successfully"
			/>

			<Modal
				isOpen={security && Object.keys(security).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={closeHelper}
			>
				{dataClone && (
					<React.Fragment>
						<ModalHeader toggle={closeHelper}>Update Real Value</ModalHeader>

						<ModalBody>
							<div>
								<div className="mb-3">
									<Label for="market" className="text-secondary">Market</Label>
									<p>
										{getMarketDisplayName(market)}
									</p>
								</div>

								<div className="mb-3">
									<Label for="securityName" className="text-secondary">Security</Label>
									<p>
										{dataClone.name}
									</p>
								</div>

								<div className="mb-3">
									<Label for="expectedValueAtExpiration" className="text-secondary">Expected Value at Expiration</Label>
									<p>
										{formatCurrency(dataClone.expectedValueAtExpiration)}
									</p>
								</div>
							</div>

							<hr/>

							<div>
								<div className="mb-3">
									<Label>Real Value</Label>
									<NumberFormat
										placeholder="Real Value"
										prefix="$"
										allowLeadingZeros={false}
										value={realValue}
										customInput={Input}
										thousandSeparator={true}
										decimalScale={2}
										onValueChange={onRealValueChange}
										allowNegative={false}
									/>
								</div>
							</div>
						</ModalBody>

						<ModalFooter>
							<Button color="link" onClick={closeHelper}>
								Cancel
							</Button>

							<Button color="primary" onClick={updateSecurityRealValue}>
								Update Security
							</Button>
						</ModalFooter>
					</React.Fragment>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IUpdateSecurityRealValueModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(UpdateSecurityRealValueModal);
